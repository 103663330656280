import {
  DownOutlined
} from "@ant-design/icons";
import { useModal } from '@ebay/nice-modal-react';
import type { MenuProps } from "antd";
import { Dropdown, Layout, Space } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useRoutes } from 'react-router-dom';
import { User } from "../bindings/User";
import { getCurrentUser, logoff } from "../ts/auth-user";
import { VERSION } from '../ts/conf';
import { getDisplayName } from '../ts/utils-user';
import AppMenu from './AppMenu';
import "./Main.pcss";
import routes from './Route';
import ChangePwdDg from './user/ChangePwdDg';

const { Content, Footer, Sider } = Layout;

const menuItems: MenuProps["items"] = [
  {
    label: "修改密码",
    key: "updatePwd",
  },
  {
    type: "divider",
  },
  {
    label: "Logout",
    key: "Logout",
  },
];

function Main() {
  const [collapsed, setCollapsed] = useState(false);
  const [currentUser, setCurrentUser] = useState(null as User | null);
  let element = useRoutes(routes);
  const pwdModal = useModal(ChangePwdDg);

  const onUserMenuClick: MenuProps["onClick"] = useCallback(({ key }:{[name:string]:any}) => {
    if(key == "Logout"){
      logoff().then(() => {
        window.location.href = "/";
      });
    }if(key == "updatePwd"){
      pwdModal.show({ user:currentUser! });
    }else{
      console.log(key);
    }
  }, [currentUser]);

  useEffect(() => {
    getCurrentUser().then((user) => {
      setCurrentUser(user)
    });
  }, []);

  return (
    <Layout className="Main">
      <div className="header">
        <div className="logo" />
        <div className="logo-title">询惠购后台管理系统</div>
        <Dropdown menu={{ items:menuItems, onClick:onUserMenuClick }} trigger={["click"]} className="user-info">
          <Space>
            {getDisplayName(currentUser)}
            <DownOutlined />
          </Space>
        </Dropdown>
      </div>
      <Layout>
        <Sider className="asign" collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
          <AppMenu />
        </Sider>
        <Layout className="panel">
          <Content>
            {element}
          </Content>
          <Footer className="footer">
            询惠购后台管理系统(版本:{VERSION}) ©{new Date().getFullYear()} Created by 成飞网络工作室
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default Main;
