import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Form, Input, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useCallback, useState } from 'react';
import { Faq } from '../../bindings/Faq';
import { faqDco } from '../../dco/dcos';
import { getCurrentUser } from '../../ts/auth-user';
import { antdModal } from '../../ts/nice-modal-fix';
import "./FaqDg.pcss";

function FaqDg({ faq }: { faq?: Faq }) {
  const modal = useModal();
  const [form] = Form.useForm();
  const [data] = useState(faq);

  const handleSubmit = useCallback(() => {
    form.validateFields().then(async () => {
      const newFaq = { ...form.getFieldsValue() };

      const curUser = await getCurrentUser();
      if(!data?.id){
        newFaq.user_id = curUser?.id;
        await faqDco.create(newFaq);
      }else{
        await faqDco.update(data?.id!, newFaq);
      }
      modal.resolve(newFaq);
      modal.hide();
    }).catch((e) => {console.log(e)});
  }, [modal, faq, form]);


  const validateMessages = {
    required: '${label}是必填项!',
  }

  const action = faq ? "修改" : "添加";
  return (
    <Modal
      {...antdModal(modal)}
      title={`${action}用户反馈`}
      okText={action}
      onOk={handleSubmit}
      className='FaqDg'
    >
      <Form form={form}
        labelCol={{ span: 5 }}
        validateMessages={validateMessages}
        initialValues={{
          question:data?.question ?? "",
          answer:data?.answer ?? "",
        }}>
        <Form.Item name="question" label="问题" rules={[{ required: true }]} >
          <Input />
        </Form.Item>
        <Form.Item name="answer" label="答案" rules={[{ required: true }]} >
          <TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default NiceModal.create(FaqDg);