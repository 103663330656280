import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Form, Input, Modal, message } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { User } from '../../bindings/User';
import { userDco } from '../../dco/dcos';
import { getCurrentUser } from '../../ts/auth-user';
import { getErrorMsg } from '../../ts/error';
import { antdModal } from '../../ts/nice-modal-fix';
import "./ChangePwdDg.pcss";

function ChangePwdDg({ user }: { user: User }) {
  const modal = useModal();
  const [form] = Form.useForm();
  const [currentUser, setCurrentUser] = useState(user as User | null);

  useEffect(() => {
    getCurrentUser().then((user) => {
      setCurrentUser(user)
    });
  }, []);

  const handleSubmit = useCallback(() => {
    form.validateFields().then(async () => {
      const newUser = { ...form.getFieldsValue() };
      try{
        await userDco.updatePwd(user?.id!, newUser.pwd_clear, newUser.repeat_pwd, newUser.pwd);
        modal.resolve(newUser);
        modal.hide();
      }catch(e){
        const error = getErrorMsg(e);
        message.error(error);
      }
    }).catch((e) => {console.log(e)});
  }, [modal, user, form]);


  const validateMessages = {
    required: '${label}是必填项!',
  }

  return (
    <Modal
      {...antdModal(modal)}
      title="修改密码"
      okText="修改"
      onOk={handleSubmit}
      className='ChangePwdDg'
    >
      <Form form={form}
        labelCol={{ span: 5 }}
        validateMessages={validateMessages}>
        {
          user && user?.id == currentUser?.id 
          ? (
            <>
              <Form.Item name="pwd" label="旧密码" rules={[{ required: true }]} >
                <Input type='password'/>
              </Form.Item>
            </>
          )
          : ""
        }
        <Form.Item name="pwd_clear" label="密码" rules={[{ required: true }]} >
          <Input type='password'/>
        </Form.Item>
        <Form.Item name="repeat_pwd" label="密码确认" rules={[
          { required: true },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('pwd_clear') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('密码不匹配！'));
            },
          }),
          ]} >
          <Input type='password'/>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default NiceModal.create(ChangePwdDg);