
import { SyncOutlined } from "@ant-design/icons";
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Breadcrumb, Button, Checkbox, Image, Pagination, Select, SelectProps, Space } from 'antd';
import Search from 'antd/es/input/Search';
import { SorterResult, SortOrder } from 'antd/es/table/interface';
import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { asArray, isNotEmpty, pruneEmpty } from 'utils-min';
import { CommoditySource } from '../../bindings/CommoditySource';
import { SourceOrder } from '../../bindings/SourceOrder';
import ScrollTable from '../../component/ScrollTable';
import { sourceOrderDco } from '../../dco/dcos';
import { APP_DATE_FORMAT, APP_TIME_FORMAT } from '../../ts/conf';
import { EnumArray, EnumConf } from '../../ts/enum-config';
import { QueryFilter, QueryOptions } from '../../ts/query_options';
import { formatCurrency } from '../../ts/utils';
import { date } from '../../ts/utils-date';
import OrderSyncDg from '../order/OrderSyncDg';
import "./SourceOrdersView.pcss";

function SourceOrdersView() {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([] as SourceOrder[]);
  const [count, setCount] = useState(0);

  let [searchParams, setSearchParams] = useSearchParams();

  const [qVal, setQVal] = useState(searchParams.get("q"));
  const [canRecord, setCanRecord] = useState(searchParams.get("canRecord") == "1");
  const [sourceVal, setSourceVal] = useState(searchParams.get("source") as CommoditySource);
  const [pageIndex, setPageIndex] = useState(searchParams.get("pageIndex") ? parseInt(searchParams.get("pageIndex")!) : 1);
  const [pageSize, setPageSize] = useState(searchParams.get("pageSize") ? parseInt(searchParams.get("pageSize")!) : 50);
  const [orderInfo, setOrderInfo] = useState({
    orderBy: searchParams.get("orderBy"),
    orderType: searchParams.get("orderBy") ? (searchParams.get("orderType") == 'desc' ? false : true) : null,
  });

  const syncModal = useModal(NiceModal.create(OrderSyncDg));

  function refresh() {
    setLoading(true);
    let filter:QueryOptions<SourceOrder> = {};
    if(isNotEmpty(qVal) || isNotEmpty(sourceVal) || canRecord){
      const filters:QueryFilter<SourceOrder&{q:string}> = {};
      if(sourceVal){
        filters.source = {'$eq': sourceVal};
      }
      if(isNotEmpty(qVal)){
        filters.q = qVal;
      }
      if(canRecord){
        filters.u_actual_fee = {'$gt': 0};
      }
      filter.filters = filters;
    }

    filter.list_options = {offset: (pageIndex - 1) * pageSize, limit: pageSize, order_bys: orderInfo.orderBy ? [`${orderInfo.orderType ? "":"!"}${orderInfo.orderBy}`] : undefined}
    sourceOrderDco.searchOrders(filter).then((result) => {
      setItems(result.list);
      setCount(result.count);
      setLoading(false);
    });
  }

  function triggerChange(){
    setSearchParams(pruneEmpty({
      q: qVal!,
      source: sourceVal,
      canRecord: canRecord ? "1" : "",
      pageIndex: pageIndex?.toString(),
      pageSize: pageSize?.toString(),
      orderBy: orderInfo?.orderBy ? orderInfo.orderBy : "",
      orderType: orderInfo?.orderBy ? `${orderInfo.orderType ? 'asc' : 'desc'}` : "",
    }))
  }

  useEffect(() => {
    triggerChange();
  }, [ sourceVal, canRecord, orderInfo, pageIndex, pageSize]);

  useEffect(() => {
    refresh();
    setSourceVal(searchParams.get("source") as CommoditySource);
    setQVal(searchParams.get("q"));
    setPageIndex(searchParams.get("pageIndex") ? parseInt(searchParams.get("pageIndex")!) : 1);
    setPageSize(searchParams.get("pageSize") ? parseInt(searchParams.get("pageSize")!) : 50);
    const newOrderInfo = {
      orderBy: searchParams.get("orderBy"),
      orderType: searchParams.get("orderBy") ? (searchParams.get("orderType") == 'desc' ? false : true) : null,
    };
    if(newOrderInfo.orderBy != orderInfo.orderBy || newOrderInfo.orderType != orderInfo.orderType ){
      setOrderInfo(newOrderInfo);
    }
  }, [searchParams]);

  async function onShowDg(){
    syncModal.show().then(() => {
      refresh();
    });
  }

  const columns = [
    {
      title: '商品信息',
      dataIndex: 'title',
      render: (_:any, rec: SourceOrder) => (
        <Space>
          <Image height={80} width={80} src={rec.main_pic}></Image>
          <Space direction='vertical'>
            <Link to={`/src-orders/${rec.id}`}>{rec.u_sku_name}</Link>
          </Space>
        </Space>
      )
    },
    {
      title: '子联盟ID',
      dataIndex: 'sub_union_id',
      width: 120,
    },
    {
      title: '订单ID',
      dataIndex: 'order_id',
      width: 140,
    },
    {
      title: '订单号',
      dataIndex: 'order_no',
      width: 140,
    },
    {
      title: '下单时间',
      width: 130,
      sorter: true,
      sortOrder: (orderInfo?.orderBy == 'u_order_time' ? (orderInfo.orderType ? 'ascend' : 'descend') : null) as SortOrder,
      key: 'u_order_time',
      render: (_:any, rec:SourceOrder) => {
        let datetime = date(rec.u_order_time!);
        return (
          <><div>{datetime.format(APP_DATE_FORMAT)}</div><div>{datetime.format(APP_TIME_FORMAT)}</div></>
        );
      },
    },
    {
      title: '更新时间',
      width: 130,
      key: 'u_modify_time',
      render: (_:any, rec:SourceOrder) => {
        let datetime = date(rec.u_modify_time!);
        return (
          <><div>{datetime.format(APP_DATE_FORMAT)}</div><div>{datetime.format(APP_TIME_FORMAT)}</div></>
        );   
      },
    },
    {
      title: '完成时间',
      width: 130,
      key: 'u_finish_time',
      render: (_:any, rec:SourceOrder) => {
        if (rec.u_finish_time) {
          let datetime = date(rec.u_finish_time!);
          return (
            <><div>{datetime.format(APP_DATE_FORMAT)}</div><div>{datetime.format(APP_TIME_FORMAT)}</div></>
          );
        }
        return "";
      },
    },
    {
      title: '实际计算佣金',
      key: 'u_actual_cos_price',
      width: 130,
      render: (_:any, rec:SourceOrder) => {
        return `￥${formatCurrency(rec.u_actual_cos_price)}`;
      },
    },
    {
      title: '实际佣金',
      width: 110,
      key: 'u_actual_fee',
      render: (_:any, rec:SourceOrder) => {
        return `￥${formatCurrency(rec.u_actual_fee)}`;
      },
    },
    {
      title: '来源',
      key: 'source',
      width: 80,
      render: (_:any, rec:SourceOrder) => {
        return EnumConf.CommoditySource[rec.source];
      },
    },
    {
      title: '预计时间',
      width: 110,
      key: 'u_pay_month',
      render: (_:any, rec:SourceOrder) => {
        return rec.u_pay_month && rec.u_pay_month != '0' ? rec.u_pay_month : '待结算';
      },
    },
  ];

  const data = (items ?? []).map((item:any) => {
    return {...item, key: item.id};
  });
  return (
    <div className="SourceOrdersView screen">
      <Breadcrumb className="breadcrumb" items={[{ title: "原始订单管理" }]} />
      <div className="screen-table-actions">
        <div className="filter-con">
          <div className='filter-item'>
            <Search placeholder="关键字搜索..." value={qVal!} onKeyUp={(e) => {
              if(e.key == 'Enter'){
                triggerChange();
              }
            }} onChange={(e) => {
              setQVal(e.target.value!);
            }}/>
          </div>
          <div className='filter-item'>
            <span>来源：</span>
            <Select
              value={sourceVal}
              onChange={(val) => {
                setSourceVal(val);
              }}
              allowClear
              placeholder="选择来源"
              options={EnumArray.CommoditySource as SelectProps["options"]}
              maxTagCount='responsive'
            />
          </div>
          <div className='filter-item'>
            <span>结算订单:&nbsp;</span>
            <Checkbox
              checked={canRecord}
              onChange={(e) => {
                setCanRecord(e.target.checked);
              }}
            />
          </div>
        </div>
        <div className="action-items">
          <Button className="action-item" type='primary' icon={<SyncOutlined />} onClick={() => onShowDg()}>同步</Button>
        </div>
      </div>
      <ScrollTable onChange={(_pg, _ft, sorter:SorterResult<SourceOrder> | SorterResult<SourceOrder>[], _extra) => {
        const sorters = asArray(sorter);
        if (isNotEmpty(sorters)){
          setOrderInfo({
            orderBy: (sorters[0]?.order ? sorters[0]?.columnKey : null) as string | null,
            orderType: sorters[0]?.order ? sorters[0]?.order == 'ascend' : null,
          });
        }
      }} loading={loading} className="screen-table" columns={columns} dataSource={data} pagination={false} />
      <Pagination showQuickJumper showSizeChanger showTotal={(total) => `总共 ${total} 条`} defaultCurrent={1} total={count} pageSize={pageSize} current={pageIndex} onChange={(pageIndex, pageSize) => {
        setPageIndex(pageIndex);
        setPageSize(pageSize);
      }} />
    </div>
  );
}

export default SourceOrdersView;
