import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';
import "./NoResult.pcss";

function NoResult(props: {}) {
  return (
    <div className="NoResult">
      <div className="main-content">
        <Result
          status="404"
          title="404"
          subTitle="您访问的地址不存在，请确认您输入的URL地址."
          extra={<Button type="primary"><Link to={"/"}>回到首页</Link></Button>}
        />
      </div>
    </div>
  );
}

export default NoResult;
