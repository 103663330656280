import { supportsAdoptingStyleSheets } from './support.js';
//#region    ---------- adoptStyle ---------- 
/**
 * Adopt a cssObject on shadowRoot (use constructable stylesheets if browser support, or append(style) if not)
 * @param el - Host to a shadowRoot or the shadowRoot itself (throw exception if not supported)
 * @return el for chainability
 */
export function adoptStyleSheets(el, cssObject) {
    const shadow = isShadowRoot(el) ? el : el.shadowRoot;
    if (shadow == null) {
        throw new Error('DOM-NATIVE ERROR - Cannot adoptStyleSheets of a non shadowRoot or an element that does not have a shadowRoot');
    }
    const cssObjects = (cssObject instanceof Array) ? cssObject : [cssObject];
    if (supportsAdoptingStyleSheets) {
        const extShadow = shadow;
        extShadow.adoptedStyleSheets = [...extShadow.adoptedStyleSheets, ...cssObjects.map(co => co.sheet)];
    }
    else {
        shadow.append(...cssObjects.map(co => co.newStyle));
    }
    return el;
}
function isShadowRoot(el) {
    return (el.host != null && el.mode != null);
}
//#endregion ---------- /adoptStyle ---------- 
//#region    ---------- css ---------- 
const constructGuard = Symbol();
// Note - use legacy way to do private for maximum Safari compatiblity (primary field was added 14.1 / 14.8, ~ 2021)
const cssTextProp = Symbol();
const styleRefProp = Symbol();
const sheetProp = Symbol();
/**
 *
 * CSSObject is the returned object from css tagged template or function call. It's immutable, and provides
 * getters to get style element from the cssText, or constructible CSSStyleSheet object (if supported by browser)
 *
 * This can be used as a stand alone utility, or with the  adoptStyle()
 */
export class CSSObject {
    constructor(cssText, guard) {
        if (guard !== constructGuard)
            throw new Error('Use css tag or css() to build a CSSObject');
        this[cssTextProp] = cssText;
    }
    get text() { return this[cssTextProp]; }
    ;
    /**
     * Returns a new style HTMLELement with the css text of this CSSObject
     * Note: (lazy created; cloned from reference style element )
     **/
    get newStyle() {
        var _a;
        (_a = this[styleRefProp]) !== null && _a !== void 0 ? _a : (this[styleRefProp] = Object.assign(document.createElement('style'), { innerHTML: this[cssTextProp] }));
        return this[styleRefProp].cloneNode(true);
    }
    /**
     * Returns the CSSStyleSheet (lazy created)
     *
     * NOTE: while the CSSObject.cssText is immutable, the returned sheet is not, and since it would not
     *            make sense to create a new sheet on each call (defeating its purpose), it is up to the user
     *            to have the appropriate strategy to mutate the returned sheet.
     *
     * NOTE: The sheet returned is only initialized once. The user is responsible to not mute it, or if muted, it is
     *       assumed the user wants it for all node that share this sheet (which is the point of shared sheet)
     **/
    get sheet() {
        if (supportsAdoptingStyleSheets) {
            if (this[sheetProp] == null) {
                this[sheetProp] = new CSSStyleSheet();
                this[sheetProp].replaceSync(this[cssTextProp]); // supportsAdoptingStyleSheets make sure this function exist
            }
            return this[sheetProp];
        }
        else {
            return null;
        }
    }
}
export function css(strings, ...values) {
    let content;
    if (typeof strings === 'string') {
        content = strings.trim();
    }
    else {
        let r = '', i = 0, vl = values.length;
        for (; i < vl; i++) {
            const v = values[i];
            const vStr = (v instanceof CSSObject) ? v.text : v;
            r += strings[i] + vStr;
        }
        // add the last one
        r += strings[i];
        // make it null proof
        content = r;
    }
    return new CSSObject(content, constructGuard);
}
//#endregion ---------- /css ----------
