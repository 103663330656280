import { ensureArray, splitAndTrim } from './utils.js';
;
//#endregion ---------- /Public Types ---------- 
//#region    ---------- Public bindHubEvents ---------- 
export function addHubEvents(target, source) {
    const t = (target == null) ? [] : (target instanceof Array) ? target : [target];
    (source instanceof Array) ? t.push(...source) : t.push(source);
    return t;
}
export function bindHubEvents(bindings, opts) {
    const bindingList = (bindings instanceof Array) ? bindings : [bindings];
    for (const bindings of bindingList) {
        const infoList = listHubInfos(bindings);
        infoList.forEach(function (info) {
            info.hub.sub(info.topics, info.labels, info.fun, opts);
        });
    }
}
/**
 * Unbinding a list of bindings. For now, MUST have nsObject.
 * @param bindings
 * @param nsObject
 */
export function unbindHubEvents(bindings, nsObject) {
    const bindingList = (bindings instanceof Array) ? bindings : [bindings];
    bindingList.forEach(function (hubEvents) {
        const infoList = listHubInfos(hubEvents);
        infoList.forEach(function (info) {
            info.hub.unsub(nsObject);
        });
    });
}
/**
 * @param {*} hubEvents could be {"hubName; topics[; labels]": fn}
 * 											or {hubName: {"topics[; labels]": fn}}
 * @returns {hub, topics, labels}[]
 */
function listHubInfos(hubEvents) {
    const infoList = [];
    for (const key in hubEvents) {
        const val = hubEvents[key];
        // If we have FnBySelector, then, hub name is in the selector, getHubInfo will extract it
        // "hubName; topics[; labels]": fn}
        if (val instanceof Function) {
            infoList.push(getHubInfo(key, null, val));
        }
        // otherwise, if val is an object, then, thee key is the name of the hub, so get/create it.
        // {hubName: {"topics[; labels]": fn}}
        else {
            const _hub = hub(key);
            for (const key2 in val) {
                infoList.push(getHubInfo(key2, _hub, val[key2]));
            }
        }
    }
    return infoList;
}
// returns {hub, topics, labels}
// hub is optional, if not present, assume the name will be the first item will be in the str
function getHubInfo(str, _hub, fun) {
    const a = splitAndTrim(str, ";");
    // if no hub, then, assume it is in the str
    const topicIdx = (_hub) ? 0 : 1;
    _hub = (!_hub) ? hub(a[0]) : _hub;
    const info = {
        topics: a[topicIdx],
        fun: fun,
        hub: _hub
    };
    if (a.length > topicIdx + 1) {
        info.labels = a[topicIdx + 1];
    }
    return info;
}
//#endregion ---------- /Private Helpers ---------- 
//#region    ---------- Public Factory ---------- 
/** Singleton hub factory */
export function hub(name) {
    if (name == null) {
        throw new Error('dom-native INVALID API CALLS: hub(name) require a name (no name was given).');
    }
    let hub = hubDic.get(name);
    // if it does not exist, we create and set it. 
    if (hub === undefined) {
        hub = new HubImpl(name);
        hubDic.set(name, hub);
        // create the hubData
        hubDataDic.set(name, new HubData(name));
    }
    return hub;
}
// User Hub object exposing the public API
const hubDic = new Map();
// Data for each hub (by name)
const hubDataDic = new Map();
class HubImpl {
    constructor(name) {
        this.name = name;
    }
    sub(topics, labels_or_handler, handler_or_opts, opts) {
        //// Build the arguments
        let labels;
        let handler;
        // if the first arg is function, then, no labels
        if (labels_or_handler instanceof Function) {
            labels = null;
            handler = labels_or_handler;
            opts = handler_or_opts;
        }
        else {
            labels = labels_or_handler;
            handler = handler_or_opts;
            // opts = opts;
        }
        //// Normalize topic and label to arrays
        const topicArray = splitAndTrim(topics, ",");
        const labelArray = (labels != null) ? splitAndTrim(labels, ",") : null;
        //// make opts (always defined at least an emtpy object)
        opts = makeOpts(opts);
        //// add the event to the hubData
        const hubData = hubDataDic.get(this.name); // by hub(...) factory function, this is garanteed
        hubData.addEvent(topicArray, labelArray, handler, opts);
    }
    unsub(ns) {
        const hubData = hubDataDic.get(this.name); // by factory contract, this always exist.
        hubData.removeRefsForNs(ns.ns);
    }
    pub(topics, labels, data) {
        // ARG SHIFTING: if data is undefined, we shift args to the RIGHT
        if (typeof data === "undefined") {
            data = labels;
            labels = null;
        }
        //// Normalize topic and label to arrays
        const topicArray = splitAndTrim(topics, ",");
        const labelArray = (labels != null) ? splitAndTrim(labels, ",") : null;
        const hubData = hubDataDic.get(this.name);
        const hasLabels = (labels != null && labels.length > 0);
        // if we have labels, then, we send the labels bound events first
        if (hasLabels) {
            hubData.getRefs(topicArray, labelArray).forEach(function (ref) {
                invokeRef(ref, data);
            });
        }
        // then, we send the topic only bound
        hubData.getRefs(topicArray, null).forEach(function (ref) {
            // if this send, has label, then, we make sure we invoke for each of this label
            if (hasLabels) {
                labelArray.forEach(function (label) {
                    invokeRef(ref, data, label);
                });
            }
            // if we do not have labels, then, just call it.
            else {
                invokeRef(ref, data);
            }
        });
    }
    deleteHub() {
        hubDic.delete(this.name);
        hubDataDic.delete(this.name);
    }
}
// TODO: This was maded to have it private to the hub. Now that we are using trypescript, we might want to use private and store it in the Hub. 
class HubData {
    constructor(name) {
        this.refsByNs = new Map();
        this.refsByTopic = new Map();
        this.refsByTopicLabel = new Map();
        this.name = name;
    }
    addEvent(topics, labels, fun, opts) {
        const refs = buildRefs(topics, labels, fun, opts);
        const refsByNs = this.refsByNs;
        const refsByTopic = this.refsByTopic;
        const refsByTopicLabel = this.refsByTopicLabel;
        refs.forEach(function (ref) {
            // add this ref to the ns dictionary
            // TODO: probably need to add an custom "ns"
            if (ref.ns != null) {
                ensureArray(refsByNs, ref.ns).push(ref);
            }
            // if we have a label, add this ref to the topicLabel dictionary
            if (ref.label != null) {
                ensureArray(refsByTopicLabel, buildTopicLabelKey(ref.topic, ref.label)).push(ref);
            }
            // Otherwise, add it to this ref this topic
            else {
                ensureArray(refsByTopic, ref.topic).push(ref);
            }
        });
    }
    ;
    getRefs(topics, labels) {
        const refs = [];
        const refsByTopic = this.refsByTopic;
        const refsByTopicLabel = this.refsByTopicLabel;
        topics.forEach(function (topic) {
            // if we do not have labels, then, just look in the topic dic
            if (labels == null || labels.length === 0) {
                const topicRefs = refsByTopic.get(topic);
                if (topicRefs) {
                    refs.push.apply(refs, topicRefs);
                }
            }
            // if we have some labels, then, take those in accounts
            else {
                labels.forEach(function (label) {
                    const topicLabelRefs = refsByTopicLabel.get(buildTopicLabelKey(topic, label));
                    if (topicLabelRefs) {
                        refs.push.apply(refs, topicLabelRefs);
                    }
                });
            }
        });
        return refs;
    }
    ;
    removeRefsForNs(ns) {
        const refsByTopic = this.refsByTopic;
        const refsByTopicLabel = this.refsByTopicLabel;
        const refsByNs = this.refsByNs;
        const refs = this.refsByNs.get(ns);
        if (refs != null) {
            // we remove each ref from the corresponding dic
            refs.forEach(function (ref) {
                // First, we get the refs from the topic or topiclabel
                let refList;
                if (ref.label != null) {
                    const topicLabelKey = buildTopicLabelKey(ref.topic, ref.label);
                    refList = refsByTopicLabel.get(topicLabelKey);
                }
                else {
                    refList = refsByTopic.get(ref.topic);
                }
                // Then, for the refList array, we remove the ones that match this object
                let idx;
                while ((idx = refList.indexOf(ref)) !== -1) {
                    refList.splice(idx, 1);
                }
            });
            // we remove them all form the refsByNs
            refsByNs.delete(ns);
        }
    }
    ;
}
// static/private
function buildRefs(topics, labels, fun, opts) {
    let refs = [];
    topics.forEach(function (topic) {
        // if we do not have any labels, then, just add this topic
        if (labels == null || labels.length === 0) {
            refs.push({
                topic: topic,
                fun: fun,
                ns: opts.ns,
                ctx: opts.ctx
            });
        }
        // if we have one or more labels, then, we add for those label
        else {
            labels.forEach(function (label) {
                refs.push({
                    topic: topic,
                    label: label,
                    fun: fun,
                    ns: opts.ns,
                    ctx: opts.ctx
                });
            });
        }
    });
    return refs;
}
// static/private: return a safe opts. If opts is a string, then, assume is it the {ns}
const emptyOpts = {};
function makeOpts(opts) {
    if (opts == null) {
        opts = emptyOpts;
    }
    else {
        if (typeof opts === "string") {
            opts = { ns: opts };
        }
    }
    return opts;
}
// static/private
function buildTopicLabelKey(topic, label) {
    return topic + "-!-" + label;
}
// static/private: call ref method (with optional label override)
function invokeRef(ref, data, label) {
    const info = {
        topic: ref.topic,
        label: ref.label || label,
        ns: ref.ns
    };
    ref.fun.call(ref.ctx, data, info);
}
//#endregion ---------- /Hub Implementation ----------
