import { Bill } from '../bindings/Bill';
import { BillForSearch } from '../bindings/BillForSearch';
import { QueryOptions } from '../ts/query_options';
import { rpc_invoke } from '../ts/rpc';
import { BaseDco } from './dco-base';



export class BillDco extends BaseDco<Bill, QueryOptions<Bill>>{
	constructor() { super('bill') }

	async searchBills(filter?: QueryOptions<Bill>): Promise<{ list: BillForSearch[], count: number }> {
		const result = await rpc_invoke(`search_${this.plural}`, { ...filter });
		return result.data;
	}

}