
/**
		 * 将数值四舍五入(保留2位小数)后格式化成金额形式
		 *
		 * @param num
		 *            数值(Number或者String)
		 * @return 金额格式的字符串,如'1,234,567.45'
		 * @type String
		 */
export function formatCurrency(value: number | string): string {
	if (typeof value == undefined || value == null) {
		return "";
	}

	const numberValue: number = typeof value === 'string' ? parseFloat(value) : value;

	if (Number.isNaN(numberValue)) {
		return "";
	}

	const formattedCurrency: string = numberValue.toFixed(2)

	return formattedCurrency;
}


export function randomString(length?: number) {
	length = length || 6;
	const arr: string[] = [];
	for (let i = 0; i < length; i++) {
		let v = parseInt((Math.random() * 10).toString());
		arr.push(v.toString());
	}
	return arr.join("");
}

