import { BillPurpose } from '../bindings/BillPurpose';

export const EnumConf = {
	UserRole: {
		Admin: "管理员",
		User: "普通用户"
	},

	CashoutStep: {
		Init: "新建",
		Pending: "等待处理",
		Sending: "提现中",
		Done: "提现成功",
		Error: "提现失败",
	},

	CommoditySource: {
		Jd: "京东",
		Tb: "淘宝",
		Pdd: "拼多多",
	},

	CommodityStatus: {
		Init: "未发布",
		Done: "已发布",
		Expired: "已过期",
	},

	CommodityElite: {
		Recommend: "精选",
		Hot: "热销",
		Coupon: "好券",
		By9p9: "9.9包邮",
	},

	VideoType: {
		Main: "主视频",
		Detail: "详情视频"
	},

	CouponType: {
		Minus: "满减",
		Discount: "打折",
	},

	CashoutPurpose: {
		Cashout: "普通提现",
		Campaign: "活动提现"
	},

	BillPurpose: {
		ShoppingRebate: "购物返利",
		CommodityReferral: "推荐商品",
		RefererReferral: "推荐用户",
		Send: "赠送",
		Cashout: "提现"
	},

	TimeType: {
		Order: "下单时间",
		Finish: "完成时间",
		Modify: "更新时间"
	},
} as { [name: string]: { [name: string]: any } }

export const EnumArray = Object.keys(EnumConf).reduce((pv, item: string) => {
	const key = item;
	const arr = Object.keys(EnumConf[key]).map(obj => {
		return { value: obj, label: EnumConf[key][obj] };
	});
	pv[key] = arr;
	return pv;
}, {} as { [c in keyof typeof EnumConf]: { value: string, label: string }[] });


export function getBillSign(bp: BillPurpose) {
	return bp == 'Cashout' ? "-" : "+";
}