import { LoadingOutlined } from "@ant-design/icons";
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Form, InputNumber, Modal, Select, SelectProps, Space, Typography } from 'antd';
import { useState } from 'react';
import { CommodityElite } from '../../bindings/CommodityElite';
import { PosIntegerValidator } from '../../ts/antd-ext';
import { EnumArray } from '../../ts/enum-config';
import { antdModal } from '../../ts/nice-modal-fix';

function CommoditySyncEliteDg(dgProps: {onSync:(elite:CommodityElite, maxTotal?:number) => void}){
  const modal = useModal();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState('');
  const [form] = Form.useForm();

  const validateMessages = {
    required: '${label}是必填项!',
  }
  
  const handleSync = async () => {
    setErr("");
    form.validateFields().then(async () => {
      try{
        setLoading(true);
        await dgProps?.onSync(form.getFieldValue("elite"), form.getFieldValue("maxTotal"));
        setLoading(false);
        modal.resolve();
        modal.hide();
      }catch(e){
        setErr("同步错误");
        setLoading(false);
      }
    })
  };

  return (
    <Modal 
     {...antdModal(modal)}
      title='同步商品'
      footer={
        <>
        <Button onClick={() => {modal.resolve(), modal.hide()}}>关闭</Button>
        <Button type='primary' icon={loading ? <LoadingOutlined /> : null} disabled={loading} onClick={handleSync}>{loading ? "同步中": "同步"}</Button>
        </>
      }
      className='CommoditySyncEliteDg'
    >
      <Form form={form} labelCol={{ span: 7 }} validateMessages={validateMessages}>
        <Form.Item 
          label="精选库"
          initialValue={{maxTotal: 20}}
        >
          <Space>
            <Form.Item name="elite" rules={[{ required: true }]} noStyle>
              <Select
                allowClear
                placeholder="选择精选库"
                options={EnumArray.CommodityElite as SelectProps["options"]}
              />
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item name="maxTotal" label="最大获取值" rules={[{ required: true}, {type:'number', min:20, message: "最小值为20" }, PosIntegerValidator]} >
          <InputNumber />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 7 }}>
          {err ? (<Typography.Text type="danger">{err}</Typography.Text>) : ""}
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default NiceModal.create(CommoditySyncEliteDg);