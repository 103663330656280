import { GzhFans } from '../bindings/GzhFans';
import { QueryOptions } from '../ts/query_options';
import { rpc_invoke } from '../ts/rpc';
import { BaseDco } from './dco-base';



export class GzhFansDco extends BaseDco<GzhFans, QueryOptions<GzhFans>> {
	constructor() { super('gzh_fans', 'gzh_fanses') }

	async syncGzhFans() {
		await rpc_invoke(`sync_${this.cmd_suffix}`, {});
	}

	async syncGzhFansDetails() {
		await rpc_invoke(`sync_${this.cmd_suffix}_details`, {});
	}

	async getGzhFansDetailsMissingCount(): Promise<number> {
		const result = await rpc_invoke(`get_${this.cmd_suffix}_details_missing_count`, {});
		return result.data;
	}

	async searchGzhFans(filter?: QueryOptions<GzhFans>): Promise<{ list: GzhFans[], count: number }> {
		const result = await rpc_invoke(`search_${this.cmd_suffix}`, { ...filter });
		return result.data;
	}
}