import React, { Suspense, lazy } from "react";
import AppIcon from '../component/AppIcon';
import Home from "./Home";
import BillsView from './bill/BillsView';
import CashoutsView from './cashout/CashoutsView';
import CommoditiesView from './commodity/CommoditiesView';
import CommodityDetail from './commodity/CommodityDetail';
import FaqsView from './faq/FaqsView';
import FeedbackDetail from './feedback/FeedbackDetail';
import FeedbacksView from './feedback/FeedbacksView';
import GzhFansView from './gzh/GzhFansView';
import OrderDetail from './order/OrderDetail';
import OrdersView from './order/OrdersView';
import SelLibsView from './sel-lib/SelLibsView';
import SourceOrderDetail from './src-order/SourceOrderDetail';
import SourceOrdersView from './src-order/SourceOrdersView';

interface RouteItem{
  path: string;
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  element: React.ReactNode,
  children?: RouteItem[],
  ismenu?: String,
}

 
// 路由懒加载
const UsersView = lazy(() => import("./user/UsersView"));
const NoMatch = lazy(() => import("./NoMatch"));
 
const routes:RouteItem[] = [
  {
    path: "/",
    key: "/",
    label: "首页",
    element: <Home />,
    icon: <AppIcon name='home' />,
    ismenu: "true",
  },
  {
    path: "/commodities/:commodityId",
    key: "/commodities/:commodityId",
    element: <CommodityDetail />,
    label: "详情页",
  },
  {
    path: "/commodities",
    key: "/commodities",
    label: "商品管理",
    icon: <AppIcon name='commodity' />,
    element: 
          (<Suspense fallback={<></>}>
            <CommoditiesView />
          </Suspense>),
    ismenu: "true",
  },
  {
    path: "/sel-libs",
    key: "/sel-libs",
    label: "选品库管理",
    icon: <AppIcon name='sel-lib' />,
    ismenu: "true",
    element: 
          (<Suspense fallback={<></>}>
            <SelLibsView />
          </Suspense>),
  },
  {
    path: "/orders/:orderId",
    key: "/orders/:orderId",
    element: <OrderDetail />,
    label: "详情页",
  },
  {
    path: "/orders",
    key: "/orders",
    label: "订单管理",
    icon: <AppIcon name='order' />,
    ismenu: "true",
    element: 
          (<Suspense fallback={<></>}>
            <OrdersView />
          </Suspense>),
  },
  {
    path: "/src-orders/:orderId",
    key: "/src-orders/:orderId",
    element: <SourceOrderDetail />,
    label: "详情页",
  },
  {
    path: "/src-orders",
    key: "/src-orders",
    label: "原始订单管理",
    icon: <AppIcon name='source-order' />,
    ismenu: "true",
    element: 
          (<Suspense fallback={<></>}>
            <SourceOrdersView />
          </Suspense>),
  },
  {
    path: "/bills",
    key: "/bills",
    label: "账单管理",
    icon: <AppIcon name='bill' />,
    ismenu: "true",
    element: 
          (<Suspense fallback={<></>}>
            <BillsView />
          </Suspense>),
  },
  {
    path: "/cashouts",
    key: "/cashouts",
    label: "提现记录",
    icon: <AppIcon name='cashout' />,
    ismenu: "true",
    element: 
          (<Suspense fallback={<></>}>
            <CashoutsView />
          </Suspense>),
  },
  {
    path: "/feedbacks/:feedbackId",
    key: "/feedbacks/:feedbackId",
    element: <FeedbackDetail />,
    label: "详情页",
  },
  {
    path: "/feedbacks",
    key: "/feedbacks",
    label: "用户反馈",
    ismenu: "true",
    icon: <AppIcon name='feedback' />,
    element: 
          (<Suspense fallback={<></>}>
            <FeedbacksView />
          </Suspense>),
  },
  {
    path: "/users",
    key: "/users",
    label: "用户管理",
    icon: <AppIcon name='user' />,
    ismenu: "true",
    element: 
          (<Suspense fallback={<></>}>
            <UsersView />
          </Suspense>),
  },
  {
    path: "/gzh",
    key: "/gzh",
    label: "公众号",
    icon: <AppIcon name='gzh' />,
    ismenu: "true",
    element: 
          (<Suspense fallback={<></>}>
            <GzhFansView />
          </Suspense>),
  },
  {
    path: "/faq",
    key: "/faq",
    label: "常见问题管理",
    icon: <AppIcon name='question' />,
    ismenu: "true",
    element: 
          (<Suspense fallback={<></>}>
            <FaqsView />
          </Suspense>),
  },
  {
    path: "*",
    key: "*",
    label: "未找到",
    icon: <AppIcon name='unfound' />,
    element: 
    (<Suspense fallback={<></>}>
    <NoMatch />
  </Suspense>),
  },
];

export default routes;