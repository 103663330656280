
import { useModal } from '@ebay/nice-modal-react';
import { Breadcrumb, Button, Pagination, Select, SelectProps, Space } from 'antd';
import Search from 'antd/es/input/Search';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { asNum, isNotEmpty, pruneEmpty } from 'utils-min';
import { Cashout } from '../../bindings/Cashout';
import { CashoutForSearch } from '../../bindings/CashoutForSearch';
import { CashoutPurpose } from '../../bindings/CashoutPurpose';
import { CashoutStep } from '../../bindings/CashoutStep';
import { PayWay } from '../../bindings/PayWay';
import { User } from '../../bindings/User';
import AppIcon from '../../component/AppIcon';
import ScrollTable from '../../component/ScrollTable';
import { cashoutDco, userDco } from '../../dco/dcos';
import { EnumArray, EnumConf } from '../../ts/enum-config';
import { QueryFilter, QueryOptions } from '../../ts/query_options';
import { formatCurrency } from '../../ts/utils';
import { getDisplayName } from '../../ts/utils-user';
import "./CashoutsView.pcss";
import PayDg from './PayDg';

function CashoutsView() {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([] as CashoutForSearch[]);
  const [count, setCount] = useState(0);
  const [userOptions, setUserOptions] = useState([] as SelectProps['options']);

  let [searchParams, setSearchParams] = useSearchParams();

  const [qVal, setQVal] = useState(searchParams.get("q"));
  const [userIds, setUserIds] = useState(searchParams.get("user_id")?.split(",")?.map(v => asNum(v)) as number[]);
  const [stepVal, setStepVal] = useState(searchParams.get("step")?.split(",") as CashoutStep[] | null);
  const [purposeVal, setPurposeVal] = useState(searchParams.get("purpose")?.split(",") as CashoutPurpose[] | null);
  const [pageIndex, setPageIndex] = useState(searchParams.get("pageIndex") ? parseInt(searchParams.get("pageIndex")!) : 1);
  const [pageSize, setPageSize] = useState(searchParams.get("pageSize") ? parseInt(searchParams.get("pageSize")!) : 50);

  const [payWay, setPayWay] = useState(null as PayWay | null); 
  const payModal = useModal(PayDg);

  function refresh() {
    setLoading(true);
    let filter:QueryOptions<Cashout> = {};
    if(isNotEmpty(userIds) || isNotEmpty(purposeVal) || isNotEmpty(stepVal) || isNotEmpty(qVal)){
      const filters:QueryFilter<Cashout> = {};
      if(isNotEmpty(userIds)){
        filters.user_id = {'$in': userIds};
      }

      if(isNotEmpty(purposeVal)){
        filters.purpose = {'$in': purposeVal};
      }

      if(isNotEmpty(stepVal)){
        filters.step = {'$in': stepVal};
      }
      if(isNotEmpty(qVal)){
        filters.trans_id = {'$eq': qVal};
      }
      filter.filters = filters;
    }

    filter.list_options = {offset: (pageIndex - 1) * pageSize, limit: pageSize}
    cashoutDco.searchCashouts(filter).then((result) => {
      setItems(result.list);
      setCount(result.count);
      setLoading(false);
    });
  }

  function triggerChange(){
    setSearchParams(pruneEmpty({
      q: qVal!,
      user_id: userIds?.join(","),
      purpose: purposeVal?.join(","),
      step: stepVal?.join(","),
      pageIndex: pageIndex?.toString(),
      pageSize: pageSize?.toString()
    }))
  }

  useEffect(() => {
    triggerChange();
  }, [userIds, purposeVal, stepVal, pageIndex, pageSize]);

  useEffect(() => {
    refresh();
    setUserIds(searchParams.get("user_id")?.split(",")?.map(v => asNum(v)) as number[]);
    setStepVal(searchParams.get("step")?.split(",") as CashoutStep[] | null);
    setPurposeVal(searchParams.get("purpose")?.split(",") as CashoutPurpose[] | null);
    setQVal(searchParams.get("q"));
    setPageIndex(searchParams.get("pageIndex") ? parseInt(searchParams.get("pageIndex")!) : 1);
    setPageSize(searchParams.get("pageSize") ? parseInt(searchParams.get("pageSize")!) : 50);
  }, [searchParams]);

  useEffect(() => {
    Promise.all([userDco.list()]).then(([options]) => {
      setUserOptions(options.map((item:User) => {
        return {label: getDisplayName(item), value: item.id};
      }))
    });
    cashoutDco.getCashoutPayWay().then(way => {
      setPayWay(way);
    });
  }, []);

  function onShowMarkDg(rec:Cashout){
    payModal.show({cashout:rec}).then(() => {
      refresh();
    });
  }

  const columns = [
    {
      title: '订单号',
      dataIndex: 'trans_id',
      width: 320,
    },
    {
      title: '用户',
      key: 'user_id',
      width: 220,
      render: (_:any, rec:CashoutForSearch) => {
        return <Space><span>{getDisplayName(rec)}</span><span>({rec.user_id})</span></Space>;
      },
    },
    {
      title: '金额',
      key: 'amount',
      width: 120,
      render: (_:any, rec:Cashout) => {
        return `￥${formatCurrency(rec.amount)}`;
      },
    },
    {
      title: '状态',
      key: 'step',
      width: 120,
      render: (_:any, rec:Cashout) => {
        return EnumConf.CashoutStep[rec.step];
      },
    },
    {
      title: '说明',
      key: 'purpose',
      width: 160,
      render: (_:any, rec:Cashout) => {
        return EnumConf.CashoutPurpose[rec.purpose];
      },
    },
    {
      key: 'note',
      title: '备注',
      dataIndex: 'note',
      render: (_:any, rec:Cashout) => {
        let remark = rec.note ?? "";
        if (remark.length > 0){
          remark += '\n';
        }
        remark += rec.extra ?? rec.err_msg ?? "";
        return remark;
      },
    },
  ];

  if (payWay == 'Manual'){
    columns.push({
      title: '操作',
      key: 'action',
      width: 200,
      render: (_:any, rec:Cashout) => {
        return !['Done', 'Error'].includes(rec.step) ? (
          <Space size="middle">
            <Button size='small' type="primary" icon={<AppIcon name='pay' />}  onClick={() => onShowMarkDg(rec)}>付款处理</Button>
          </Space>
        ) : '';
      },
    });
  }
  
  const data = (items ?? []).map((item:any) => {
    return {...item, key: item.id};
  });
  return (
    <div className="CashoutsView screen">
      <Breadcrumb className="breadcrumb" items={[{ title: "提现记录" }]} />
      <div className="screen-table-actions">
        <div className="filter-con">
          <div className='filter-item'>
            <Search placeholder="关键字搜索..." value={qVal!} onKeyUp={(e) => {
              if(e.key == 'Enter'){
                triggerChange();
              }
            }} onChange={(e) => {
              setQVal(e.target.value!);
            }}/>
          </div>
          <div className='filter-item'>
            <span>用户：</span>
            <Select
              value={userIds}
              onChange={(val) => {
                setUserIds(val);
              }}
              mode="multiple"
              allowClear
              placeholder="选择用户"
              options={userOptions}
              maxTagCount='responsive'
            />
          </div>
          <div className='filter-item'>
            <span>来源：</span>
            <Select
              value={purposeVal}
              onChange={(val) => {
                setPurposeVal(val);
              }}
              mode="multiple"
              allowClear
              placeholder="选择说明"
              options={EnumArray.CashoutPurpose as SelectProps["options"]}
              maxTagCount='responsive'
            />
          </div>
          <div className='filter-item'>
            <span>状态：</span>
            <Select
              value={stepVal}
              onChange={(val) => {
                setStepVal(val);
              }}
              mode="multiple"
              allowClear
              placeholder="选择状态"
              options={EnumArray.CashoutStep as SelectProps["options"]}
              maxTagCount='responsive'
            />
          </div>
        </div>
      </div>
      <ScrollTable loading={loading} className="screen-table" columns={columns} dataSource={data} pagination={false} />
      <Pagination showQuickJumper showSizeChanger showTotal={(total) => `总共 ${total} 条`} defaultCurrent={1} total={count} pageSize={pageSize} current={pageIndex} onChange={(pageIndex, pageSize) => {
        setPageIndex(pageIndex);
        setPageSize(pageSize);
      }} />
    </div>
  );
}

export default CashoutsView;
