import { User } from '../bindings/User';
import { apiPrx } from './conf';
import { webGet, webPost } from './web-request';


export async function login(username: string, pwd: string) {
	return webPost(`${apiPrx}/login`, { body: { username, pwd } }).then(result => {
		return result;
	});
}


export function logoff() {
	return webPost(`${apiPrx}/logoff`, { body: { logoff: true } }).then(result => {
		return result;
	});
}


export async function isLogin() {
	return (<any>window).contextData?.user ? true : false;
}

export async function getUserContext() {
	const contextResult = (await webGet(`${apiPrx}/user-context`, {})) || {};
	const contextData = contextResult.result || {};
	const uc = (<any>window).contextData = contextData;
	return uc;
}

export async function clearUserContext() {
	(<any>window).contextData = undefined;
}


export async function getCurrentUser() {
	const contextData = (<any>window).contextData;
	if (contextData) {
		return contextData.user as User;
	}
	return null;
}

