import NiceModal from '@ebay/nice-modal-react';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from 'react-router-dom';
import "./main.pcss";
import { refreshCookieTimeout } from './ts/auth';
import { getUserContext, isLogin } from './ts/auth-user';
import { hub } from './ts/dom-native/hub';
import Login from './views/Login';
import Main from './views/Main';

document.addEventListener("DOMContentLoaded", function (event) {

	let refreshing = false;
	hub("ajaxHub").sub("DONE", (info: any) => {
		refreshCookieTimeout(info.expired);
	});

	hub("ajaxHub").sub("ERROR", async (error: any) => {
		if (error?.error?.message == "NO_AUTH" && !refreshing) {
			refreshing = true;
			await refreshPage(error);
			refreshing = false;
		}
	});

	refreshPage();
});

async function refreshPage(error?:any) {
	await getUserContext();
	// if no UC, we display the LoginView
	ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
		<ConfigProvider locale={zhCN}>
			<React.StrictMode>
				<NiceModal.Provider>
					{
						await isLogin() 
						? <Router><Main></Main></Router>
						: <Login globalErrorCode={error}></Login>
					}
				</NiceModal.Provider>
			</React.StrictMode>
		</ConfigProvider>
	);
	
}