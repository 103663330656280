import { BillDco } from './dco-bill.js';
import { CashoutDco } from './dco-cashout.js';
import { CommodityCouponDco } from './dco-commodity-coupon.js';
import { CommodityImageDco } from './dco-commodity-image.js';
import { CommodityVideoDco } from './dco-commodity-video.js';
import { CommodityDco } from './dco-commodity.js';
import { FaqDco } from './dco-faq.js';
import { FeedbackDco } from './dco-feedback.js';
import { GzhFansDco } from './dco-gzh-fans.js';
import { SelLibDco } from './dco-sec-lib.js';
import { SourceOrderDco } from './dco-source-order.js';
import { UserOrderDco } from './dco-user-order.js';
import { UserDco } from './dco-user.js';


export const userDco = new UserDco();
export const commodityDco = new CommodityDco();
export const selLibDco = new SelLibDco();
export const feedbackDco = new FeedbackDco();
export const commodityImageDco = new CommodityImageDco();
export const commodityVideoDco = new CommodityVideoDco();
export const commodityCouponDco = new CommodityCouponDco();
export const userOrderDco = new UserOrderDco();
export const sourceOrderDco = new SourceOrderDco();
export const cashoutDco = new CashoutDco();
export const billDco = new BillDco();
export const gzhFansDco = new GzhFansDco();
export const faqDco = new FaqDco();