import { useModal } from '@ebay/nice-modal-react';
import type { DatePickerProps, SelectProps } from 'antd';
import { DatePicker, Form, Modal, Select, Typography, message } from 'antd';
import { useCallback, useState } from 'react';
import { userOrderDco } from '../../dco/dcos';
import { APP_DATETIME_FORMAT } from '../../ts/conf';
import { EnumArray } from '../../ts/enum-config';
import { getErrorMsg } from '../../ts/error';
import { antdModal } from '../../ts/nice-modal-fix';
import { date, now } from '../../ts/utils-date';
import "./OrderSyncDg.pcss";

let days = 90 * 24 * 60 * 60;

// Disabled 7 days from the selected date
const disabledDaysDate: DatePickerProps['disabledDate'] = (current, {from}) => {
  if (now().diff(current, 'seconds') >= days){
    return true;
  }
  return false;
};

function OrderSyncDg() {
  const modal = useModal();
  const [form] = Form.useForm();
  const [syncing, setSyncing] = useState(false);
  const [endTime, setEndTime] = useState("");

  const handleSubmit = useCallback(() => {
    form.validateFields().then(async () => {
      setSyncing(true);
      const data = { ...form.getFieldsValue() };
      try{
        let start_time = data.start_time.toISOString();
        await userOrderDco.syncOrders(start_time, data.time_type);
      }catch(e){
        const msg = getErrorMsg(e);
        message.error(msg);
      }
      modal.resolve();
      modal.hide();
    }).catch((e) => {console.log(e)});
  }, [modal, form]);


  const validateMessages = {
    required: '${label}是必填项!',
  }

  function startChange(startTime:string){
    const time = date(startTime, APP_DATETIME_FORMAT);
    if(time.isValid()){
      setEndTime(time.add(1, "hour").format(APP_DATETIME_FORMAT));
    }else{
      setEndTime("请输入开始时间");
    }
  }
  return (
    <Modal
      {...antdModal(modal)}
      title={`同步订单`}
      okText={syncing ? "正在同步...":"同步"}
      okButtonProps={{disabled:syncing}}
      onOk={handleSubmit}
      className='OrderSyncDg'
    >
      <Form form={form}
        labelCol={{ span: 7 }}
        validateMessages={validateMessages}>
        <Form.Item name="start_time" label="开始时间" rules={[
          { required: true}
        ]}>
          <DatePicker showTime disabledDate={disabledDaysDate} onChange={startChange} />
        </Form.Item>
        <Form.Item label="截止时间">
          {endTime ? endTime : (<Typography.Text type="secondary">请选择输入时间</Typography.Text>)}
        </Form.Item>
        <Form.Item name="time_type" label="时间类型" rules={[
          { required: true}
        ]}>
          <Select
            allowClear
            placeholder="选择时间类型"
            options={EnumArray.TimeType as SelectProps["options"]}
            maxTagCount='responsive'
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

// 2 places use the dialog, so use single function to export, and create modal each component
// export default NiceModal.create(OrderSyncDg);
export default OrderSyncDg;