import { deepFreeze } from 'utils-min';
import { CountStats } from '../bindings/CountStats';
import { User } from '../bindings/User';
import { apiPrx } from '../ts/conf';
import { QueryOptions } from '../ts/query_options';
import { rpc_invoke } from '../ts/rpc';
import { webPost } from '../ts/web-request';
import { BaseDco } from './dco-base';



export class UserDco extends BaseDco<User, QueryOptions<User>> {
	constructor() { super('user') }

	async searchUsers(filter?: QueryOptions<User & { q: string }>): Promise<{ list: User[], count: number }> {
		const result = await rpc_invoke(`search_${this.plural}`, { ...filter });
		return result.data;
	}

	async updatePwd(user_id: number, pwd_clear: string, repeat_pwd: string, pwd?: string): Promise<void> {
		let response: any = await webPost(`${apiPrx}/update-user-pwd`, { body: { user_id, pwd_clear, repeat_pwd, pwd } });
		if (response.error != null) {
			throw response.error;
		} else {
			return deepFreeze(response.result);
		}
	}

	async getUserStats(): Promise<CountStats> {
		const result = await rpc_invoke(`get_user_stats`, {});
		return result.data;
	}
}

