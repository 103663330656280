import { useModal } from '@ebay/nice-modal-react';
import { Breadcrumb, Button, Pagination, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isNotEmpty, pruneEmpty } from 'utils-min';
import { GzhFans } from '../../bindings/GzhFans';
import ConfirmDg from '../../component/ConfirmDg';
import ScrollTable from '../../component/ScrollTable';
import { gzhFansDco } from '../../dco/dcos';
import { QueryFilter, QueryOptions } from '../../ts/query_options';
import "./GzhFansView.pcss";

function GzhFansView() {

  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([] as GzhFans[]);
  const [count, setCount] = useState(0);

  let [searchParams, setSearchParams] = useSearchParams();

  const [qVal, setQVal] = useState(searchParams.get("q"));
  const [pageIndex, setPageIndex] = useState(searchParams.get("pageIndex") ? parseInt(searchParams.get("pageIndex")!) : 1);
  const [pageSize, setPageSize] = useState(searchParams.get("pageSize") ? parseInt(searchParams.get("pageSize")!) : 50);

  const [missingCount, setMissingCount] = useState(null as number | null);
  const confirmModal = useModal(ConfirmDg);

  useEffect(() => {
    refresh();
  }, []);

  function refresh() {
    setLoading(true);
    let filter:QueryOptions<GzhFans> = {};
    if(isNotEmpty(qVal)){
      const filters:QueryFilter<GzhFans&{q:string}> = {};
      if(isNotEmpty(qVal)){
        filters.q = qVal;
      }
      filter.filters = filters;
    }

    filter.list_options = {offset: (pageIndex - 1) * pageSize, limit: pageSize}
    gzhFansDco.searchGzhFans(filter).then((result) => {
      setItems(result.list);
      setCount(result.count);
      setLoading(false);
    });

    gzhFansDco.getGzhFansDetailsMissingCount().then(count => {
      setMissingCount(count as number);
    });
  }

  function syncGzhFans(){
    confirmModal.show({
      content: '确定公众号同步粉丝列表吗？',
      onDone: async () => {
        await gzhFansDco.syncGzhFans();
        refresh();
      }
    });
  }

  function syncGzhFansDetails(){
    confirmModal.show({
      content: '同步公众号粉丝unionId吗？',
      onDone: async () => {
        await gzhFansDco.syncGzhFansDetails();
        refresh();
      }
    });
  }

  function triggerChange(){
    setSearchParams(pruneEmpty({
      q: qVal!,
      pageIndex: pageIndex?.toString(),
      pageSize: pageSize?.toString()
    }))
  }

  useEffect(() => {
    triggerChange();
  }, [ pageIndex, pageSize]);

  useEffect(() => {
    refresh();
    setQVal(searchParams.get("q"));
    setPageIndex(searchParams.get("pageIndex") ? parseInt(searchParams.get("pageIndex")!) : 1);
    setPageSize(searchParams.get("pageSize") ? parseInt(searchParams.get("pageSize")!) : 50);
  }, [searchParams]);

  const columns = [
    {
      title: 'OpenID',
      dataIndex: 'open_id',
      width: 200,
    },
    {
      title: 'UnionID',
      dataIndex: 'union_id',
      width: 200,
    },
    {
      title: '昵称',
      dataIndex: 'nickname',
      width: 160,
    },
    {
      title: '备注',
      dataIndex: 'remark',
    },
  ];

  const data = (items ?? []).map((item:any) => {
    return {...item, key: item.id};
  });

  return (
    <div className="GzhFansView screen">
      <Breadcrumb className="breadcrumb" items={[{ title: "公众号" }]} />
      <div className="screen-table-actions">
        <div className="filter-con">
        </div>
        <div className="action-items">
          <Space>
            <Button type='primary' onClick={syncGzhFans}>同步公众号粉丝列表</Button>
            <Space>
              <Button type='primary' onClick={syncGzhFansDetails}>同步公众号粉丝unionId</Button>
              <Typography.Text type='secondary'>有{missingCount == null ? "" : missingCount}个粉丝的unionid不存在</Typography.Text>
            </Space>
          </Space>
        </div>
      </div>
      <ScrollTable loading={loading} className="screen-table" columns={columns} dataSource={data} pagination={false} />
      <Pagination showQuickJumper showSizeChanger showTotal={(total) => `总共 ${total} 条`} defaultCurrent={1} total={count} pageSize={pageSize} current={pageIndex} onChange={(pageIndex, pageSize) => {
        setPageIndex(pageIndex);
        setPageSize(pageSize);
      }} />
    </div>
  );
}

export default GzhFansView;





