import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Modal, message } from 'antd';
import { getErrorMsg } from '../ts/error';
import { antdModal } from '../ts/nice-modal-fix';
import './AppIcon.pcss';


interface ConfirmDgProps{
  title?: string;
  content?: string;
  className?: string;
  onDone?: () => Promise<void>;
  onCancel?: () => Promise<void>;
  onClose?: () => Promise<void>;
}

function ConfirmDg(props: ConfirmDgProps) {
  const modal = useModal();

  async function onDone(){
    try{
      if(props.onDone){
        await props.onDone();
      }
      if(props.onClose){
        await props.onClose();
      }
      modal.resolve();
      modal.hide();
    }catch(e){
      const msg = getErrorMsg(e);
      message.error(msg);
    }
  }

  async function onCancel(){
    try{
      if(props.onCancel){
        await props.onCancel();
      }
      if(props.onClose){
        await props.onClose();
      }
      modal.resolve();
      modal.hide();
    }catch(e){
      const msg = getErrorMsg(e);
      message.error(msg);
    }
  }

  return (
    <Modal
      {...antdModal(modal)}
      title={props.title ?? `警告信息`}
      okText="确定"
      cancelText="取消"
      onOk={onDone}
      onCancel={onCancel}
      className={`ConfirmDg ${props.className ?? ""}`}
    >
      {props.content ?? `确定删除这条记录？`}
    </Modal>
  );
}

export default NiceModal.create(ConfirmDg);