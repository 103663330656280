import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Form, Modal, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useCallback, useEffect, useState } from 'react';
import { Commodity } from '../../bindings/Commodity';
import { CommodityContent } from '../../bindings/CommodityContent';
import { commodityDco } from '../../dco/dcos';
import { antdModal } from '../../ts/nice-modal-fix';
import "./CommodityContentDg.pcss";

function CommodityContentDg({ commodity }: { commodity: Commodity }) {
  const modal = useModal();
  const [form] = Form.useForm();
  const [data] = useState(commodity);
  const [commodityContent, setCommodityContent] = useState(null as CommodityContent | null);

  useEffect(()=>{
    commodityDco.getContent(data.id!).then((result:CommodityContent | null) => {
      setCommodityContent(result);
    })
  }, []);

  useEffect(()=>{
    const initValues = {
      detail_images:commodityContent?.detail_images ?? "",
    }
    form.setFieldsValue(initValues);
  }, [commodityContent]);

  const handleSubmit = useCallback(() => {
    form.validateFields().then(async () => {
      const formData = { ...form.getFieldsValue() };
      await commodityDco.saveContent(data.id, formData.detail_images);
      modal.resolve();
      modal.hide();
    }).catch((e) => {console.log(e)});
  }, [modal, commodity, form]);


  const validateMessages = {
    required: '${label}是必填项!',
  }

  return (
    <Modal
      {...antdModal(modal)}
      title={`编辑商品详情`}
      okText={"保存"}
      onOk={handleSubmit}
      className='CommodityContentDg'
      width={600}
    >
      <Form form={form}
        layout='vertical'
        validateMessages={validateMessages}
        initialValues={{
        }}>
        <Form.Item label="名称:">
          <span>{commodity.title}</span>
        </Form.Item>
        <Form.Item name="detail_images" label="商品详图:" rules={[{ required: true }]} >
          <TextArea />
        </Form.Item>
        <Form.Item  >
          <Typography.Text type="secondary">请填写正确的图片url值，并用逗号(",")隔开</Typography.Text>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default NiceModal.create(CommodityContentDg);