import { Menu } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import "./AppMenu.pcss";
import routes from './Route';

// const menus = routes.filter(item => {
//   return !["*"].includes(item.path);
// });
const menus = routes.filter(item => {
  return item.ismenu;
}).map(item => {
  item.children = item.children?.filter(it => {
    return it.ismenu;
  }).map(it => {
    return it;
  });

  if(item.children && item.children?.length == 0 ){
    item.children = undefined;
  }
  return item;
});

function AppMenu(props: {}) {
  const navigate = useNavigate();
  let location = useLocation();

  const onClick = (e:any) => {
    let path = e.keyPath.reverse().join("/");
    navigate(path);
  };

  return (
    <Menu className='AppMenu' theme="dark" defaultSelectedKeys={["/"]} selectedKeys={location.pathname.split("/").map(p => `/${p}`).slice(1)} mode="inline" items={menus} onClick={onClick} />
  );
}

export default AppMenu;
