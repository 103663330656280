import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useModal } from '@ebay/nice-modal-react';
import { Breadcrumb, Button, Pagination, Space } from 'antd';
import Search from 'antd/es/input/Search';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isNotEmpty, pruneEmpty } from 'utils-min';
import { Faq } from '../../bindings/Faq';
import ConfirmDg from '../../component/ConfirmDg';
import ScrollTable from '../../component/ScrollTable';
import { faqDco } from '../../dco/dcos';
import { QueryFilter, QueryOptions } from '../../ts/query_options';
import FaqDg from './FaqDg';
import "./FaqsView.pcss";

function FaqsView() {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([] as Faq[]);
  const [count, setCount] = useState(0);

  let [searchParams, setSearchParams] = useSearchParams();

  const [qVal, setQVal] = useState(searchParams.get("q"));
  const [pageIndex, setPageIndex] = useState(searchParams.get("pageIndex") ? parseInt(searchParams.get("pageIndex")!) : 1);
  const [pageSize, setPageSize] = useState(searchParams.get("pageSize") ? parseInt(searchParams.get("pageSize")!) : 50);

  const faqModal = useModal(FaqDg);
  const confirmModal = useModal(ConfirmDg);
  function refresh() {
    setLoading(true);
    let filter:QueryOptions<Faq> = {};
    if(isNotEmpty(qVal)){
      const filters:QueryFilter<Faq & {q:string}> = {};

      if(isNotEmpty(qVal)){
        filters.q = qVal;
      }
      filter.filters = filters;
    }

    filter.list_options = {offset: (pageIndex - 1) * pageSize, limit: pageSize}
    faqDco.searchFaqs(filter).then((result) => {
      setItems(result.list);
      setCount(result.count);
      setLoading(false);
    });
  }

  function triggerChange(){
    setSearchParams(pruneEmpty({
      q: qVal!,
      pageIndex: pageIndex?.toString(),
      pageSize: pageSize?.toString()
    }))
  }

  useEffect(() => {
    triggerChange();
  }, [pageIndex, pageSize]);

  useEffect(() => {
    refresh();
    setQVal(searchParams.get("q"));
    setPageIndex(searchParams.get("pageIndex") ? parseInt(searchParams.get("pageIndex")!) : 1);
    setPageSize(searchParams.get("pageSize") ? parseInt(searchParams.get("pageSize")!) : 50);
  }, [searchParams]);

  const columns = [
    {
      title: '问题',
      key: 'title',
      render: (_:any, rec:Faq) => {
        return rec.question;
      }
    },
    {
      title: '操作',
      width: 240,
      key: 'action',
      render: (_:any, rec:Faq) => (
        <Space size="middle">
          <Button size='small' type="primary" icon={<EditOutlined />}  onClick={() => onShowDg(rec)}>修改</Button>
          <Button size='small' danger icon={<DeleteOutlined />}  onClick={() => onDel(rec)}>删除</Button>
        </Space>
      ),
    },
  ];

  async function onShowDg(faq?:Faq){
    faqModal.show({ faq }).then(() => {
      refresh();
    });
  }

  async function onDel(faq:Faq){
    confirmModal.show({
      content: '确定删除该问题吗？',
      onDone: async () => {
        await faqDco.delete(faq.id);
        refresh();
      }
    });
  }

  
  const data = (items ?? []).map((item:any) => {
    return {...item, key: item.id};
  });
  return (
    <div className="FaqsView screen">
      <Breadcrumb className="breadcrumb" items={[{ title: "常见问题管理" }]} />
      <div className="screen-table-actions">
        <div className="filter-con">
          <div className='filter-item'>
            <Search placeholder="关键字搜索..." value={qVal!} onKeyUp={(e) => {
              if(e.key == 'Enter'){
                triggerChange();
              }
            }} onChange={(e) => {
              setQVal(e.target.value!);
            }}/>
          </div>
        </div>
        <div className="action-items">
          <Button className="action-item" type='primary' icon={<PlusOutlined />} onClick={() => onShowDg()}>添加</Button>
        </div>
      </div>
      <ScrollTable loading={loading} className="screen-table" columns={columns} dataSource={data} pagination={false} />
      <Pagination showQuickJumper showSizeChanger showTotal={(total) => `总共 ${total} 条`} defaultCurrent={1} total={count} pageSize={pageSize} current={pageIndex} onChange={(pageIndex, pageSize) => {
        setPageIndex(pageIndex);
        setPageSize(pageSize);
      }} />
    </div>
  );
}

export default FaqsView;





