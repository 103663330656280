
export const IntegerValidator = {
	pattern: /^-?\d+$/,
	message: '请输入一个有效的整数!',
};

export const PosIntegerValidator = {
	pattern: /^\d+$/,
	message: '请输入一个有效的正整数!',
};

export const RebateValidator = {
	pattern: /^\d+(\.\d{0,2})?$/,
	message: '请输入一个有效的数值(0-100)!',
};

export const RebateScaleValidator = {
	pattern: /^\d+(\.\d{0,2})?$/,
	validator: (_: any, val: any) => {
		if (val > 0 && val < 100) {
			return Promise.resolve();
		}
		return Promise.reject(new Error('请输入一个有效的数值(0-100)!'));
	},
	message: '请输入一个有效的比例值!',
};

export const CurrencyValidator = {
	pattern: /^\d+(\.\d{0,2})?$/,
	message: '请输入一个有效的金额!',
};

export const NumberValidator = {
	pattern: /^-?\d+(\.\d+)?$/,
	message: '请输入一个有效的数值!',
};

export const PosNumberValidator = {
	pattern: /^\d+(\.\d+)?$/,
	message: '请输入一个有效的正数值!',
};
