import { Image } from 'antd';
import { useEffect, useState } from 'react';
import { css } from '../../ts/dom-native/css';

const _compCss = css`
	:host{
	}
  ul,li{
    margin: 0;
    padding: 0;
  }
  img{
    width: 99%;
  }
`
function ProductDetail (props: {content: string}) {
  let [detailImages, setDetailImages] = useState([] as JSX.Element[] | null);
  useEffect(() => {
    // 现在的格式是{imageUrl},{imageUrl},...
    const content = props.content ?? "";
    let imageEls = content.split(",").map((imgUrl) => {
      return <Image src={imgUrl.trim()}></Image>;
    } )
    setDetailImages(imageEls);
  }, [props.content])
  return (
    <div className="ProductDetail">
      {detailImages ?? ""}
    </div>
  )
}
export default ProductDetail;

