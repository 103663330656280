import { SyncOutlined } from "@ant-design/icons";

import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Breadcrumb, Button, Image, Modal, Pagination, Select, SelectProps, Space, Typography } from 'antd';
import Search from 'antd/es/input/Search';
import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { asNum, isNotEmpty, pruneEmpty } from 'utils-min';
import { User } from '../../bindings/User';
import { UserOrder } from '../../bindings/UserOrder';
import { UserOrderForSearch } from '../../bindings/UserOrderForSearch';
import AppIcon from '../../component/AppIcon';
import ScrollTable from '../../component/ScrollTable';
import { userDco, userOrderDco } from '../../dco/dcos';
import { EnumConf } from '../../ts/enum-config';
import { QueryFilter, QueryOptions } from '../../ts/query_options';
import { formatCurrency } from '../../ts/utils';
import { getDisplayName } from '../../ts/utils-user';
import OrderSyncDg from './OrderSyncDg';
import "./OrdersView.pcss";

function OrdersView() {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([] as UserOrderForSearch[]);
  const [count, setCount] = useState(0);
  const [userOptions, setUserOptions] = useState([] as SelectProps['options']);

  let [searchParams, setSearchParams] = useSearchParams();

  const [qVal, setQVal] = useState(searchParams.get("q"));
  const [userIds, setUserIds] = useState(searchParams.get("user_id")?.split(",")?.map(v => asNum(v)) as number[]);
  const [pageIndex, setPageIndex] = useState(searchParams.get("pageIndex") ? parseInt(searchParams.get("pageIndex")!) : 1);
  const [pageSize, setPageSize] = useState(searchParams.get("pageSize") ? parseInt(searchParams.get("pageSize")!) : 50);

  const syncModal = useModal(NiceModal.create(OrderSyncDg));
  function refresh() {
    setLoading(true);
    let filter:QueryOptions<UserOrder> = {};
    if(isNotEmpty(userIds) || isNotEmpty(qVal)){
      const filters:QueryFilter<UserOrderForSearch&{q:string}> = {};
      if(isNotEmpty(userIds)){
        filters.user_id = {'$in': userIds};
      }
      if(isNotEmpty(qVal)){
        filters.q = qVal;
      }
      filter.filters = filters;
    }

    filter.list_options = {offset: (pageIndex - 1) * pageSize, limit: pageSize}
    userOrderDco.searchOrders(filter).then((result) => {
      setItems(result.list);
      setCount(result.count);
      setLoading(false);
    });
  }

  function triggerChange(){
    setSearchParams(pruneEmpty({
      q: qVal!,
      user_id: userIds?.join(","),
      pageIndex: pageIndex?.toString(),
      pageSize: pageSize?.toString()
    }))
  }

  useEffect(() => {
    triggerChange();
  }, [userIds, pageIndex, pageSize]);

  useEffect(() => {
    refresh();
    setUserIds(searchParams.get("user_id")?.split(",")?.map(v => asNum(v)) as number[]);
    setQVal(searchParams.get("q"));
    setPageIndex(searchParams.get("pageIndex") ? parseInt(searchParams.get("pageIndex")!) : 1);
    setPageSize(searchParams.get("pageSize") ? parseInt(searchParams.get("pageSize")!) : 50);
  }, [searchParams]);

  useEffect(() => {
    Promise.all([userDco.list()]).then(([options]) => {
      setUserOptions(options.map((item:User) => {
        return {label: getDisplayName(item), value: item.id};
      }))
    })
  }, []);

  const columns = [
    {
      title: '商品信息',
      dataIndex: 'title',
      render: (_:any, rec: UserOrderForSearch) => (
        <Space>
          <Image height={80} width={80} src={rec.main_pic}></Image>
          <Space direction='vertical'>
            <Link to={`/orders/${rec.id}`}>{rec.u_sku_name}</Link>
          </Space>
        </Space>
      )
    },
    {
      title: '用户',
      key: 'sub_union_id',
      width: 120,
      render: (_:any, rec:UserOrderForSearch) => {
        let username = rec.username;
        return username ? <Space direction='vertical'><span>{getDisplayName(rec as any)}</span></Space> : "";
      },
    },
    {
      title: '推荐用户',
      dataIndex: 'referrer_id',
      width: 120,
      render: (_:any, rec:UserOrderForSearch) => {
        let referrer_username = rec.referrer_username;
        return referrer_username ? <Space direction='vertical'><span>{getDisplayName({username: referrer_username, name: rec.referrer_user_name} as any)}</span><span>({rec.referrer_id})</span></Space> : "";
      },
    },
    {
      title: '订单号',
      dataIndex: 'order_no',
      width: 150,
    },
    {
      title: '价格',
      key: 'u_price',
      width: 110,
      render: (_:any, rec:UserOrderForSearch) => {
        return `￥${formatCurrency(rec.u_price)}`;
      },
    },
    {
      title: '实际佣金',
      width: 110,
      key: 'u_actual_cos_price',
      render: (_:any, rec:UserOrderForSearch) => {
        return `￥${formatCurrency(rec.u_actual_cos_price)}`;
      },
    },
    {
      title: '实际比例',
      width: 110,
      key: 'u_final_rate',
      render: (_:any, rec:UserOrderForSearch) => {
        return `${rec.u_final_rate}%`;
      },
    },
    {
      title: '来源',
      key: 'source',
      width: 110,
      render: (_:any, rec:UserOrderForSearch) => {
        return EnumConf.CommoditySource[rec.source];
      },
    },
    {
      title: '预计时间',
      width: 110,
      key: 'u_pay_month',
      render: (_:any, rec:UserOrderForSearch) => {
        return rec.u_pay_month && rec.u_pay_month != '0' ? rec.u_pay_month : '待结算';
      },
    },
    {
      title: '操作',
      key: 'action',
      width: 110,
      render: (_:any, rec:UserOrder) => (
        <Space size="middle">
          {
            rec.recorded ? <Typography.Text type="success">已结算</Typography.Text> : (<Button size='small' type="primary" icon={<AppIcon name='settle' />}  onClick={() => onSettle(rec)}>结算</Button>)
          }
        </Space>
      ),
    },
  ];

  async function onShowDg(){
    syncModal.show().then(() => {
      refresh();
    });
  }

  async function onSettle(rec: UserOrder){
    Modal.confirm({
      title: '结算确认',
      content: '确认要结算这个订单吗？',
      onOk: () => {
        userOrderDco.recordedOrders([rec.id]).then(() => {
          refresh();
        });
      }
    });
  }
  
  const data = (items ?? []).map((item:any) => {
    return {...item, key: item.id};
  });
  return (
    <div className="OrdersView screen">
      <Breadcrumb className="breadcrumb" items={[{ title: "订单管理" }]} />
      <div className="screen-table-actions">
        <div className="filter-con">
          <div className='filter-item'>
            <Search placeholder="关键字搜索..." value={qVal!} onKeyUp={(e) => {
              if(e.key == 'Enter'){
                triggerChange();
              }
            }} onChange={(e) => {
              setQVal(e.target.value!);
            }}/>
          </div>
          <div className='filter-item'>
            <span>用户：</span>
            <Select
              value={userIds}
              onChange={(val) => {
                setUserIds(val);
              }}
              mode="multiple"
              allowClear
              placeholder="选择用户"
              options={userOptions}
              maxTagCount='responsive'
            />
          </div>
        </div>
        <div className="action-items">
          <Button className="action-item" type='primary' icon={<SyncOutlined />} onClick={() => onShowDg()}>同步</Button>
        </div>
      </div>
      <ScrollTable loading={loading} className="screen-table" columns={columns} dataSource={data} pagination={false} />
      <Pagination showQuickJumper showSizeChanger showTotal={(total) => `总共 ${total} 条`} defaultCurrent={1} total={count} pageSize={pageSize} current={pageIndex} onChange={(pageIndex, pageSize) => {
        setPageIndex(pageIndex);
        setPageSize(pageSize);
      }} />
    </div>
  );
}

export default OrdersView;
