import { Breadcrumb, Card, Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { CountStats } from '../bindings/CountStats';
import { ValueStats } from '../bindings/ValueStats';
import { userDco, userOrderDco } from '../dco/dcos';
import { formatCurrency } from '../ts/utils';
import "./Home.pcss";

function Home(props: {}) {

  const [userStats, setUserStats] = useState( null as CountStats | null);
  const [userOrderStats, setUserOrderStats] = useState( null as CountStats | null);
  const [userOrderEarningStats, setUserOrderEarningStats] = useState( null as ValueStats | null);
  
  useEffect(() => {
    userDco.getUserStats().then(results => {
      setUserStats(results);
    });
    userOrderDco.getUserOrderStats().then(results => {
      setUserOrderStats(results);
    });
    userOrderDco.getUserOrderEarningStats().then(results => {
      setUserOrderEarningStats(results);
    });
  }, []);

  return (
    <div className="Home">
      <Breadcrumb className="breadcrumb" items={[{ title: "欢迎进入询惠购后台管理系统！" }]} >
      </Breadcrumb>
      <div className="main-content">
        <Row gutter={32}>
          <Col span={8}>
            <Card title="用户统计">
              <div>今日新增: &nbsp;&nbsp;{userStats ? userStats.today : '' }</div>
              <div>昨日新增: &nbsp;&nbsp;{userStats ? userStats.yesterday : ''}</div>
              <div>近7天新增: &nbsp;&nbsp;{userStats ? userStats.last_7_days : ''}</div>
              <div>近30天新增: &nbsp;&nbsp;{userStats ? userStats.last_30_days : ''}</div>
            </Card>
          </Col>
          <Col span={8}>
            <Card title="订单统计">
              <div>今日新增: &nbsp;&nbsp;{userOrderStats ? userOrderStats.today : '' }</div>
              <div>昨日新增: &nbsp;&nbsp;{userOrderStats ? userOrderStats.yesterday : ''}</div>
              <div>近7天新增: &nbsp;&nbsp;{userOrderStats ? userOrderStats.last_7_days : ''}</div>
              <div>近30天新增: &nbsp;&nbsp;{userOrderStats ? userOrderStats.last_30_days : ''}</div>
            </Card>
          </Col>
          <Col span={8}>
            <Card title="订单收益">
              <div>今日收益: &nbsp;&nbsp;{userOrderEarningStats ? formatCurrency(userOrderEarningStats.today) : '' }</div>
              <div>昨日收益: &nbsp;&nbsp;{userOrderEarningStats ? formatCurrency(userOrderEarningStats.yesterday) : ''}</div>
              <div>近7天收益: &nbsp;&nbsp;{userOrderEarningStats ? formatCurrency(userOrderEarningStats.last_7_days) : ''}</div>
              <div>近30天收益: &nbsp;&nbsp;{userOrderEarningStats ? formatCurrency(userOrderEarningStats.last_30_days) : ''}</div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Home;
