import { Feedback } from '../bindings/Feedback';
import { FeedbackForSearch } from '../bindings/FeedbackForSearch';
import { QueryOptions } from '../ts/query_options';
import { rpc_invoke } from '../ts/rpc';
import { BaseDco } from './dco-base';



export class FeedbackDco extends BaseDco<Feedback, QueryOptions<Feedback & { q: string }>>{
	constructor() { super('feedback') }

	async searchFeedbacks(filter?: QueryOptions<Feedback & { q: string }>): Promise<{ list: FeedbackForSearch[], count: number }> {
		const result = await rpc_invoke(`search_${this.plural}`, { ...filter });
		return result.data;
	}
}