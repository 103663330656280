import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Form, Input, InputNumber, Modal, Select } from 'antd';
import { useCallback, useState } from 'react';
import { Commodity } from '../../bindings/Commodity';
import { commodityDco } from '../../dco/dcos';
import { CurrencyValidator, PosIntegerValidator, RebateScaleValidator, RebateValidator } from '../../ts/antd-ext';
import { antdModal } from '../../ts/nice-modal-fix';
import "./CommodityDg.pcss";

function CommodityDg({ commodity }: { commodity?: Commodity }) {
  const modal = useModal();
  const [form] = Form.useForm();
  const [data] = useState(commodity);

  const handleSubmit = useCallback(() => {
    form.validateFields().then(async () => {
      const newCommodity = { ...form.getFieldsValue() };

      if(!data?.id){
        await commodityDco.create(newCommodity);
      }else{
        await commodityDco.update(data?.id!, newCommodity);
      }
      modal.resolve(newCommodity);
      modal.hide();
    }).catch((e) => {console.log(e)});
  }, [modal, commodity, form]);


  const validateMessages = {
    required: '${label}是必填项!',
  }

  const action = commodity ? "修改" : "添加";
  return (
    <Modal
      {...antdModal(modal)}
      title={`${action}商品`}
      okText={action}
      onOk={handleSubmit}
      className='CommodityDg'
      width={600}
    >
      <Form form={form}
        labelCol={{ span: 8 }}
        validateMessages={validateMessages}
        initialValues={{
          title:data?.title ?? "",
          product_id:data?.product_id ?? "",
          main_pic:data?.main_pic ?? "",
          main_url:data?.main_url ?? "",
          click_url:data?.click_url ?? "",
          click_short_url:data?.click_short_url ?? "",
          wechat_short_link:data?.wechat_short_link ?? "",
          comments:data?.comments ?? "",
          price:data?.price ?? "",
          org_price:data?.org_price ?? "",
          shop_name:data?.shop_name ?? "",
          sell_count:data?.sell_count ?? "",
          source:data?.source ?? "Jd",

          commission:data?.commission ?? 0,
          commission_share:data?.commission_share ?? 0,
          coupon_commission:data?.coupon_commission ?? 0,
          plus_commission_share:data?.plus_commission_share ?? 0,
        }}>
        <Form.Item name="title" label="名称" rules={[{ required: true }]} >
          <Input />
        </Form.Item>
        <Form.Item name="product_id" label="产品ID" rules={[{ required: true }]} >
          <Input />
        </Form.Item>
        <Form.Item name="main_pic" label="主图链接" rules={[{ required: true }, {type:'url'}]} >
          <Input />
        </Form.Item>
        <Form.Item name="main_url" label="商品链接" rules={[{ required: true }, {type:'url'}]} >
          <Input />
        </Form.Item>
        <Form.Item name="click_url" label="推广链接" rules={[{type:'url'}]} >
          <Input />
        </Form.Item>
        <Form.Item name="click_short_url" label="短链接" rules={[
          {type:'url'}, 
          ]} >
          <Input />
        </Form.Item>
        <Form.Item name="wechat_short_link" label="微信链接" rules={[{type:'url'}]} >
          <Input />
        </Form.Item>
        <Form.Item name="comments" label="评论量" rules={[{ required: true }, PosIntegerValidator]} >
          <InputNumber />
        </Form.Item>
        <Form.Item name="sell_count" label="月销售量" rules={[{ required: true }, PosIntegerValidator]} >
          <InputNumber />
        </Form.Item>
        <Form.Item name="price" label="优惠价" rules={[{ required: true}, CurrencyValidator]} >
        <InputNumber step="0.01"/>
        </Form.Item>
        <Form.Item name="org_price" label="原价" rules={[{ required: true}, CurrencyValidator]} >
          <InputNumber step="0.01"/>
        </Form.Item>
        <Form.Item name="shop_name" label="店铺名称" rules={[{ required: true }]} >
          <Input />
        </Form.Item>
        <Form.Item name="source" label="来源" rules={[{ required: true }]} >
          <Select>
            <Select.Option value="Jd">京东</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="commission" label="商品佣金" rules={[{ required: true}, RebateValidator]} >
          <InputNumber />
        </Form.Item>
        <Form.Item name="commission_share" label="商品佣金比例" rules={[{ required: true}, RebateScaleValidator]} >
          <InputNumber />
        </Form.Item>
        <Form.Item name="coupon_commission" label="商品优惠券佣金" rules={[{ required: true}, RebateValidator]} >
          <InputNumber />
        </Form.Item>
        <Form.Item name="plus_commission_share" label="商品plus用户佣金" rules={[{ required: true}, RebateValidator]} >
          <InputNumber />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default NiceModal.create(CommodityDg);