import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Form, Modal, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useCallback } from 'react';
import { CommoditySource } from '../../bindings/CommoditySource';
import { selLibDco } from '../../dco/dcos';
import { antdModal } from '../../ts/nice-modal-fix';
import "./SyncDg.pcss";

function SyncDg() {
  const modal = useModal();
  const [form] = Form.useForm();

  const handleSubmit = useCallback(() => {
    form.validateFields().then(async () => {
      const newSelLib = { ...form.getFieldsValue() };
      // get items from html
      const content = newSelLib.content;
      const templateDiv = document.createElement("div");
      templateDiv.innerHTML = content;
      const items = Array.prototype.map.call(templateDiv.querySelectorAll(".batch-item:not(.create)"), (itemEl:HTMLElement) => {
        const titleEl = itemEl.querySelector(".batch-title > a > span");
        return {sel_lib_union_id: itemEl.getAttribute("value"), name: titleEl?.innerHTML!, source: 'Jd'};
      }) as {sec_lib_id:string, name:string, source: CommoditySource}[];
      await selLibDco.sync(items, 'Jd');
      modal.resolve(items);
      modal.hide();
    }).catch((e) => {console.log(e)});
  }, [modal, form]);


  const validateMessages = {
    required: '${label}是必填项!',
  }

  return (
    <Modal
      {...antdModal(modal)}
      title={`同步选品库`}
      okText="同步"
      onOk={handleSubmit}
      className='SyncDg'
    >
      <Form form={form}
        layout='vertical'
        validateMessages={validateMessages}>
        <Form.Item name="content" label="请输入有效的html" rules={[{ required: true }]}>
          <TextArea />
        </Form.Item>
        <Form.Item  >
          <Typography.Text type="secondary">请从京东联盟选品库里，通过右键检查选项查看并复制batch-area这个div节点的所有html代码</Typography.Text>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default NiceModal.create(SyncDg);