import { Button, Form, Input, Space, Typography } from 'antd';
import { useCallback, useState } from 'react';
import { login } from '../ts/auth-user';
import "./Login.pcss";

function Login(props: {globalErrorCode: any}) {
  const [form] = Form.useForm();
  const [err, setErr] = useState('');

  const handleSubmit = useCallback(() => {
    form.validateFields().then(async () => {
    const data = { ...form.getFieldsValue() };
    try{
      const result = await login(data.username, data.pwd);
      if (result.result.success) {
        window.location.href = '/';
        return;
      } else {
        if(result.error.message == "NO_ACCESS"){
          setErr("没有足够权限登录!");
        }else{
          setErr("请输入正确的用户名或密码！");
        }
      }
    }catch(e:any){
      setErr("请输入正确的用户名或密码！");
    }
  }).catch((e) => {console.log(e)});
  }, []);
  return (
    <div className="Login">
      <div className="login-box">
        <header>登录</header>
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ offset: 1, span: 15 }}
          onFinish={handleSubmit}
          // onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="用户名"
            name="username"
            rules={[{ required: true, message: '请输入用户名!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="密码"
            name="pwd"
            rules={[{ required: true, message: '请输入密码!' }]}
          >
            <Input.Password />
          </Form.Item>

        
          <Form.Item wrapperCol={{ offset: 5, span: 15 }}>
            <Space direction="vertical">
              {err ? (<Typography.Text type="danger">{err}</Typography.Text>) : ""}
              <Button type="primary" htmlType="submit">
                登录
              </Button>
            </Space>
          </Form.Item>
        </Form>
        <footer className='footer-action'>
          <a href="/terms.html" target='_blank'>用户协议</a>
          <a href="/privacy.html" target='_blank'>隐私政策</a>
        </footer>
      </div>
    </div>
  );
}

export default Login;
