import { Breadcrumb, Carousel, Flex, Image, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { asNum } from 'utils-min';
import { Commodity } from '../../bindings/Commodity';
import { CommodityContent } from '../../bindings/CommodityContent';
import { CommodityCoupon } from '../../bindings/CommodityCoupon';
import { CommodityImage } from '../../bindings/CommodityImage';
import { CommodityVideo } from '../../bindings/CommodityVideo';
import { commodityCouponDco, commodityDco, commodityImageDco, commodityVideoDco } from '../../dco/dcos';
import { formatCurrency } from '../../ts/utils';
import { best_value_from_coupons } from '../../ts/utils-commodity';
import NoResult from '../NoResult';
import "./CommodityDetail.pcss";
import CouponGetTag from './CouponGetTag';
import ProductDetail from './ProductDetail';

function CommodityDetail() {
  const {commodityId} = useParams();
  const [isLoading, setIsLoading] = useState(true); // 初始时设为加载中状态
  const [commodity, setCommodity] = useState(null as Commodity | null);
  const [images, setImages] = useState(null as CommodityImage[] | null);
  const [videos, setVideos] = useState(null as CommodityVideo[] | null);
  const [coupons, setCoupons] = useState(null as CommodityCoupon[] | null);
  const [commodityContent, setCommodityContent] = useState(null as CommodityContent | null);
  useEffect(() => {
    const id = asNum(commodityId)!;
    Promise.all([
      commodityDco.get(id), 
      commodityImageDco.list({filters: [{commodity_id: {"$eq": id}}]}),
      commodityVideoDco.list({filters: [{commodity_id: {"$eq": id}}]}),
      commodityCouponDco.list({filters: [{commodity_id: {"$eq": id}}]}),
      commodityDco.getContent(id),
    ]).then(([c, images, videos, coupons,content]) => {
      setCommodity(c);
      setImages(images);
      setVideos(videos);
      setCoupons(coupons);
      setIsLoading(false);
      setCommodityContent(content);
    })
  }, []);

  async function goShortUrl(){
    let url = await commodityDco.getShortUrl(commodity?.id!);
    window.open(url, "_blank");
  }

  return (
    <div className="CommodityDetail">
      <Breadcrumb className="breadcrumb" items={[{ title: <Link to="/commodities">商品管理</Link> }, { title: "详情页" }, ]} />
      
      <div className='content'>
        {
          isLoading 
            ? ""
            : (
              commodity 
                ? (
                  detailPage(commodity, images, videos, coupons, commodityContent, {goShortUrl})
                )
                : (<NoResult />)
          )
        }
        
      </div>
    </div>
  );
}

function detailPage(commodity: Commodity, images: CommodityImage[] | null, videos:CommodityVideo[] | null, coupons:CommodityCoupon[] | null, commodityContent:CommodityContent | null, props:any){
  return (
    <Space direction="vertical" className='detail-main'>
      <Space>
        <a href={commodity.main_url} target='_blank'>原商品链接</a>
        <a onClick={() => props.goShortUrl()}>推广链接</a>
      </Space>
      <Carousel autoplay>
        {
          images?.map((img, index) => {
            return (
              <Image key={index} src={img.url}></Image>
            )
          })
        }
      </Carousel>
      <Flex className='price-con'>
        <Typography.Text className='text' strong>券后</Typography.Text>
        <Typography.Text className='price cur-price' >￥{commodity.price}</Typography.Text>
        <Typography.Text className='price orig-price' delete disabled>￥{commodity.org_price}</Typography.Text>
        <Typography.Text className='rebate'>约返￥{formatCurrency(commodity.coupon_commission)}</Typography.Text>
        <Typography.Text className='shop-sells' type="secondary">月销量：{commodity.sell_count}笔</Typography.Text>
      </Flex>
      <Flex className='title-con'>
        <Typography.Title level={5}>
          <Image
          src='/images/jd.png'
          preview={false}
        />{commodity.title}
        </Typography.Title>
      </Flex>
      <Flex>
        <div className='tag-con'>
          <CouponGetTag val={best_value_from_coupons(coupons!)!}></CouponGetTag>
        </div>
      </Flex>
      {
        commodityContent?.detail_images 
         ? <>
            <Flex className='sec-header'>
              <Image
                src='/images/commodity.png'
                preview={false}
              />
              <Typography.Text >商品详情</Typography.Text>
            </Flex>
            <Space className='detail-origin-con'>
            <ProductDetail content={commodityContent?.detail_images ?? ""} />
            </Space>
          </>
         : ""
      }
    </Space>
  );
}

export default CommodityDetail;