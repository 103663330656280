import { isEmpty } from 'utils-min';
import { CommodityCoupon } from '../bindings/CommodityCoupon';

export interface CouponInfo {
  text: string,
  style: string,
  coupon: CommodityCoupon
}
export function best_value_from_coupons(coupons?: CommodityCoupon[]): CouponInfo | null {
  if (isEmpty(coupons)) {
    return null;
  }
  let bestCoupon = coupons?.filter(c => c.is_best)[0];
  if (!bestCoupon) {
    bestCoupon = coupons![0];
  }
  return {
    text: bestCoupon.coupon_style == 'Minus' ? `${bestCoupon.discount}元券` : `${bestCoupon.discount * 10}折`,
    style: bestCoupon.coupon_style == 'Minus' ? `元券` : `折券`,
    coupon: bestCoupon
  };
}