import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Form, Input, Modal } from 'antd';
import { useCallback, useState } from 'react';
import { SelLib } from '../../bindings/SelLib';
import { selLibDco } from '../../dco/dcos';
import { antdModal } from '../../ts/nice-modal-fix';
import "./SelLibDg.pcss";

function SelLibDg({ selLib }: { selLib?: SelLib }) {
  const modal = useModal();
  const [form] = Form.useForm();
  const [data] = useState(selLib);

  const handleSubmit = useCallback(() => {
    form.validateFields().then(async () => {
      const newSelLib = { ...form.getFieldsValue() };

      if(!data?.id){
        await selLibDco.create(newSelLib);
      }else{
        await selLibDco.update(data?.id!, newSelLib);
      }
      modal.resolve(newSelLib);
      modal.hide();
    }).catch((e) => {console.log(e)});
  }, [modal, selLib, form]);


  const validateMessages = {
    required: '${label}是必填项!',
  }

  const action = selLib ? "修改" : "添加";
  return (
    <Modal
      {...antdModal(modal)}
      title={`${action}选品库`}
      okText={action}
      onOk={handleSubmit}
      className='SelLibDg'
    >
      <Form form={form}
        labelCol={{ span: 5 }}
        validateMessages={validateMessages}
        initialValues={{
          name:data?.name ?? "",
          sel_lib_union_id:data?.sel_lib_union_id ?? "",
        }}>
        <Form.Item name="name" label="名称" rules={[{ required: true }]} >
          <Input />
        </Form.Item>
        <Form.Item name="sel_lib_union_id" label="选品库ID" rules={[{ required: true }]} >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default NiceModal.create(SelLibDg);