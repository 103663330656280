import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useModal } from '@ebay/nice-modal-react';
import { Breadcrumb, Button, Pagination, Select, SelectProps, Space } from 'antd';
import Search from 'antd/es/input/Search';
import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { asNum, isNotEmpty, pruneEmpty } from 'utils-min';
import { Feedback } from '../../bindings/Feedback';
import { FeedbackForSearch } from '../../bindings/FeedbackForSearch';
import { User } from '../../bindings/User';
import ConfirmDg from '../../component/ConfirmDg';
import ScrollTable from '../../component/ScrollTable';
import { feedbackDco, userDco } from '../../dco/dcos';
import { QueryFilter, QueryOptions } from '../../ts/query_options';
import { getDisplayName } from '../../ts/utils-user';
import FeedbackDg from './FeedbackDg';
import "./FeedbacksView.pcss";

function FeedbacksView() {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([] as FeedbackForSearch[]);
  const [count, setCount] = useState(0);
  const [userOptions, setUserOptions] = useState([] as SelectProps['options']);

  let [searchParams, setSearchParams] = useSearchParams();

  const [qVal, setQVal] = useState(searchParams.get("q"));
  const [userIds, setUserIds] = useState(searchParams.get("user_id")?.split(",")?.map(v => asNum(v)) as number[]);
  const [pageIndex, setPageIndex] = useState(searchParams.get("pageIndex") ? parseInt(searchParams.get("pageIndex")!) : 1);
  const [pageSize, setPageSize] = useState(searchParams.get("pageSize") ? parseInt(searchParams.get("pageSize")!) : 50);

  const feedbackModal = useModal(FeedbackDg);
  const confirmModal = useModal(ConfirmDg);
  function refresh() {
    setLoading(true);
    let filter:QueryOptions<Feedback> = {};
    if(isNotEmpty(userIds) || isNotEmpty(qVal)){
      const filters:QueryFilter<Feedback & {q:string}> = {};

      if(isNotEmpty(userIds)){
        filters.user_id = {'$in': userIds}
      }

      if(isNotEmpty(qVal)){
        filters.q = qVal;
      }
      filter.filters = filters;
    }

    filter.list_options = {offset: (pageIndex - 1) * pageSize, limit: pageSize}
    feedbackDco.searchFeedbacks(filter).then((result) => {
      setItems(result.list);
      setCount(result.count);
      setLoading(false);
    });
  }

  function triggerChange(){
    setSearchParams(pruneEmpty({
      q: qVal!,
      user_id: userIds?.join(","),
      pageIndex: pageIndex?.toString(),
      pageSize: pageSize?.toString()
    }))
  }

  useEffect(() => {
    triggerChange();
  }, [userIds, pageIndex, pageSize]);

  useEffect(() => {
    refresh();
    setUserIds(searchParams.get("user_id")?.split(",")?.map(v => asNum(v)) as number[]);
    setQVal(searchParams.get("q"));
    setPageIndex(searchParams.get("pageIndex") ? parseInt(searchParams.get("pageIndex")!) : 1);
    setPageSize(searchParams.get("pageSize") ? parseInt(searchParams.get("pageSize")!) : 50);
  }, [searchParams]);

  useEffect(() => {
    Promise.all([userDco.list()]).then(([options]) => {
      setUserOptions(options.map((item:User) => {
        return {label: getDisplayName(item), value: item.id};
      }))
    })
  }, []);

  const columns = [
    {
      title: '标题',
      key: 'title',
      render: (_:any, rec:FeedbackForSearch) => {
        return <Link to={`/feedbacks/${rec.id}`}>{rec.title}</Link>;
      }
    },
    {
      title: '用户',
      key: 'user_id',
      width: 240,
      render: (_:any, rec:FeedbackForSearch) => {
        return <Space ><span>{getDisplayName(rec)}</span><span>({rec.user_id})</span></Space>;
      },
    },
    {
      title: '操作',
      width: 240,
      key: 'action',
      render: (_:any, rec:Feedback) => (
        <Space size="middle">
          <Button size='small' type="primary" icon={<EditOutlined />}  onClick={() => onShowDg(rec)}>修改</Button>
          <Button size='small' danger icon={<DeleteOutlined />}  onClick={() => onDel(rec)}>删除</Button>
        </Space>
      ),
    },
  ];

  async function onShowDg(feedback?:Feedback){
    feedbackModal.show({ feedback }).then(() => {
      refresh();
    });
  }

  async function onDel(feedback:Feedback){
    confirmModal.show({
      content: '确定删除该用户反馈吗？',
      onDone: async () => {
        await feedbackDco.delete(feedback.id);
        refresh();
      }
    });
  }

  
  const data = (items ?? []).map((item:any) => {
    return {...item, key: item.id};
  });
  return (
    <div className="FeedbacksView screen">
      <Breadcrumb className="breadcrumb" items={[{ title: "用户反馈" }]} />
      <div className="screen-table-actions">
        <div className="filter-con">
          <div className='filter-item'>
            <Search placeholder="关键字搜索..." value={qVal!} onKeyUp={(e) => {
              if(e.key == 'Enter'){
                triggerChange();
              }
            }} onChange={(e) => {
              setQVal(e.target.value!);
            }}/>
          </div>
          <div className='filter-item'>
            <span>用户：</span>
            <Select
              value={userIds}
              onChange={(val) => {
                setUserIds(val);
              }}
              mode="multiple"
              allowClear
              placeholder="选择用户"
              options={userOptions}
              maxTagCount='responsive'
            />
          </div>
        </div>
        <div className="action-items">
          <Button className="action-item" type='primary' icon={<PlusOutlined />} onClick={() => onShowDg()}>添加</Button>
        </div>
      </div>
      <ScrollTable loading={loading} className="screen-table" columns={columns} dataSource={data} pagination={false} />
      <Pagination showQuickJumper showSizeChanger showTotal={(total) => `总共 ${total} 条`} defaultCurrent={1} total={count} pageSize={pageSize} current={pageIndex} onChange={(pageIndex, pageSize) => {
        setPageIndex(pageIndex);
        setPageSize(pageSize);
      }} />
    </div>
  );
}

export default FeedbacksView;





