import { LoadingOutlined } from "@ant-design/icons";
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Button, Form, Modal, Select, SelectProps, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { SelLib } from '../../bindings/SelLib';
import { selLibDco } from '../../dco/dcos';
import { antdModal } from '../../ts/nice-modal-fix';
import SyncDg from '../sel-lib/SyncDg';
import './CommoditySyncDg.pcss';

function CommonditySyncDg(dgProps: {onSync:(selLibId:string) => void}){
  const modal = useModal();
  const [loading, setLoading] = useState(false);
  const [selLibOptions, setSelLibOptions] = useState([] as SelectProps['options']);
  const [err, setErr] = useState('');
  const [form] = Form.useForm();
  const syncModal = useModal(SyncDg)

  useEffect(() => {
    refresh();
  }, []);

  const validateMessages = {
    required: '${label}是必填项!',
  }
  
  const handleSync = async () => {
    setErr("");
    form.validateFields().then(async () => {
      try{
        setLoading(true);
        await dgProps?.onSync(form.getFieldValue("sel_lib_union_id"));
        setLoading(false);
        modal.resolve();
        modal.hide();
      }catch(e){
        setErr("同步错误");
        setLoading(false);
      }
    })
  };

  async function refresh(){
    Promise.all([selLibDco.list()]).then(([options]) => {
      setSelLibOptions(options.map((item:SelLib) => {
        return {label: item.name, value: item.sel_lib_union_id};
      }))
    })
  }

  async function onSelLibSyncDg(){
    syncModal.show().then(() => {
      refresh();
    });
  }

  return (
    <Modal 
     {...antdModal(modal)}
      title='同步商品'
      footer={
        <>
        <Button onClick={() => {modal.resolve(), modal.hide()}}>关闭</Button>
        <Button type='primary' icon={loading ? <LoadingOutlined /> : null} disabled={loading} onClick={handleSync}>{loading ? "同步中": "同步"}</Button>
        </>
      }
      className='CommonditySyncDg'
    >
      <Form form={form} labelCol={{ span: 7 }} validateMessages={validateMessages}>
        <Form.Item label="选品库">
          <Space>
            <Form.Item name="sel_lib_union_id" rules={[{ required: true }]} noStyle>
              <Select
                allowClear
                placeholder="选择商品库"
                options={selLibOptions}
              />
            </Form.Item>
            <Button type="link"  onClick={() => onSelLibSyncDg()}>同步选品库</Button>
          </Space>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 7 }}>
          {err ? (<Typography.Text type="danger">{err}</Typography.Text>) : ""}
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default NiceModal.create(CommonditySyncDg);