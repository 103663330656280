import { QuestionCircleOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Descriptions, DescriptionsProps, Image, Modal, Space, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { asNum } from 'utils-min';
import { SourceOrder } from '../../bindings/SourceOrder';
import { User } from '../../bindings/User';
import { UserOrder } from '../../bindings/UserOrder';
import AppIcon from '../../component/AppIcon';
import { sourceOrderDco, userDco, userOrderDco } from '../../dco/dcos';
import { APP_DATETIME_FORMAT } from '../../ts/conf';
import { EnumConf } from '../../ts/enum-config';
import { formatCurrency } from '../../ts/utils';
import { date } from '../../ts/utils-date';
import { getDisplayName } from '../../ts/utils-user';
import NoResult from '../NoResult';
import "./OrderDetail.pcss";

function OrderDetail() {
  const {orderId} = useParams();
  const [isLoading, setIsLoading] = useState(true); // 初始时设为加载中状态
  const [userOrder, setUserOrder] = useState(null as UserOrder | null);
  const [sourceOrder, setSourceOrder] = useState(null as SourceOrder | null);
  const [user, setUser] = useState(null as User | null);
  const [referrer, setReferrer] = useState(null as User | null);

  function refresh(){
    const id = asNum(orderId)!;
    Promise.all([
      userOrderDco.get(id), 
    ]).then(([c]) => {
      setUserOrder(c);
      setIsLoading(false);
      if(c.user_id){
        userDco.get(c.user_id).then(user=>{
          setUser(user);
        })
      }
      if(c.referrer_id){
        userDco.get(c.referrer_id).then(user=>{
          setReferrer(user);
        })
      }

      sourceOrderDco.get(c.source_order_id).then(data=>{
        setSourceOrder(data);
      })
    })
  }

  useEffect(() => {
    refresh();
  }, []);

  async function onSettle(rec: UserOrder){
    Modal.confirm({
      title: '结算确认',
      content: '确认要结算这个订单吗？',
      onOk: () => {
        userOrderDco.recordedOrders([rec.id]).then(() => {
          refresh();
        });
      }
    });
  }

  return (
    <div className="OrderDetail">
      <Breadcrumb className="breadcrumb" items={[{ title: <Link to="/orders">订单管理</Link> }, { title: "详情页" }, ]} />
      
      <div className='content'>
        {
          isLoading 
            ? ""
            : (
              userOrder
                ? (
                  detailPage(userOrder, sourceOrder, user, referrer, onSettle)
                )
                : (<NoResult />)
          )
        }
        
      </div>
    </div>
  );
}

function detailPage(userOrder: UserOrder, srcOrder: SourceOrder | null,  user:User | null, referrer:User | null,onSettle:Function){

  const borderedItems: DescriptionsProps['items'] = [
    {
      key: 'main_pic',
      label: '图片',
      span: 1,
      children: (<Image height={80} width={80} src={srcOrder?.main_pic}></Image>),
    },
    {
      key: 'u_sku_name',
      label: '名称',
      span: 3,
      children: srcOrder?.u_sku_name,
    },
    {
      key: 'u_sku_id',
      label: 'skuID',
      children: srcOrder?.u_sku_id,
    },
    {
      key: 'order_no',
      label: '用户订单号',
      children: userOrder.user_order_no,
    },
    {
      key: 'user_id',
      label: '用户',
      children: <Space direction='vertical'>
        <span>({getDisplayName(user)})</span>
      </Space>,
    },
    {
      key: 'referrer_id',
      label: '推荐用户',
      children: <Space direction='vertical'>
        <span>({getDisplayName(referrer)})</span>
        <span>{userOrder.referrer_id}</span>
      </Space>,
    },
    {
      key: 'u_order_time',
      label: '下单时间',
      children: <Space direction='vertical' align='center'>{date(srcOrder?.u_order_time).format(APP_DATETIME_FORMAT).split(" ").map(item => <div key={item}>{item}</div>)}</Space>,
    },
    {
      key: 'u_modify_time',
      label: '更新时间',
      children: <Space direction='vertical' align='center'>{date(srcOrder?.u_modify_time).format(APP_DATETIME_FORMAT).split(" ").map(item => <div key={item}>{item}</div>)}</Space>,
    },
    {
      key: 'u_finish_time',
      label: '完成时间',
      children: <Space direction='vertical' align='center'>{srcOrder?.u_finish_time ? date(srcOrder?.u_finish_time).format(APP_DATETIME_FORMAT).split(" ").map(item => <div key={item}>{item}</div>) : ""}</Space>,
    },
    {
      key: 'u_price',
      label: '价格',
      children: `￥${formatCurrency(srcOrder?.u_price!)}`,
    },
    {
      key: 'rebate',
      label: '用户提成比例',
      children: `${userOrder?.rebate ? `${userOrder?.rebate}%` : ""}`,
    },
    {
      key: 'u_estimate_cos_price',
      label: <>
              预估佣金额
              &nbsp;
              <Tooltip title="由订单的实付金额拆分至每个商品的预估计佣金额，不包括运费，以及京券、东券、E卡、余额等虚拟资产支付的金额。仅为预估值，实际佣金以actualCosPrice为准进行计算" ><QuestionCircleOutlined /></Tooltip>
            </>,
      children: `￥${formatCurrency(srcOrder?.u_estimate_cos_price!)}`,
    },
    {
      key: 'u_estimate_fee',
      label: <>
              推客预估佣金
              &nbsp;
              <Tooltip title="（预估计佣金额*佣金比例*最终比例），如订单完成前发生退款，此金额也会更新" ><QuestionCircleOutlined /></Tooltip>
            </>,
      children: `￥${formatCurrency(srcOrder?.u_estimate_fee!)}`,
    },
    {
      key: 'u_actual_cos_price',
      label: <>
              实际佣金
              &nbsp;
              <Tooltip title="订单完成后，会将误扣除的运费券金额更正。如订单完成后发生退款，此金额会更新" ><QuestionCircleOutlined /></Tooltip>
            </>,
      children: `￥${formatCurrency(srcOrder?.u_actual_cos_price!)}`,
    },
    {
      key: 'u_actual_fee',
      label: <>
              推客实际佣金
              &nbsp;
              <Tooltip title="（实际计佣金额*佣金比例*最终比例）。如订单完成后发生退款，此金额会更新" ><QuestionCircleOutlined /></Tooltip>
            </>,
      children: `￥${formatCurrency(srcOrder?.u_actual_fee!)}`,
    },
    {
      key: 'u_commission_rate',
      label: '计划佣金比例',
      children: `${srcOrder?.u_commission_rate}%`,
    },
    {
      key: 'u_sub_side_rate',
      label: '分成比例',
      children: `${srcOrder?.u_sub_side_rate}%`,
    },
    {
      key: 'u_sub_sidy_rate',
      label: '补贴比例',
      children: `${srcOrder?.u_sub_sidy_rate}%`,
    },
    {
      key: 'u_final_rate',
      label: '实际比例',
      children: `${srcOrder?.u_final_rate}%`,
    },
    {
      key: 'source',
      label: '来源',
      children: EnumConf.CommoditySource[srcOrder?.source!],
    },
    {
      key: 'u_ext1',
      label: '扩展字段',
      children: srcOrder?.u_ext1,
    },
    {
      key: 'u_pay_month',
      label: '预估结算时间',
      children: srcOrder?.u_pay_month,
    },
  ];

  return (
    <Space direction="vertical" className='detail-main'>
      <a href={srcOrder?.main_url} target='_blank'>原商品链接</a>
      
      <Descriptions
        bordered
        column={4}
        title="订单详情信息"
        extra={userOrder.recorded ? <Typography.Text type="success">已结算</Typography.Text> : <Button size='small' type="primary" icon={<AppIcon name='settle' />}  onClick={() => onSettle(userOrder)}>结算</Button>}
        items={borderedItems}
      />

    </Space>
  );
}

export default OrderDetail;