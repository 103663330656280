import { Faq } from '../bindings/Faq';
import { QueryOptions } from '../ts/query_options';
import { rpc_invoke } from '../ts/rpc';
import { BaseDco } from './dco-base';



export class FaqDco extends BaseDco<Faq, QueryOptions<Faq & { q: string }>> {
	constructor() { super('faq') }

	async searchFaqs(filter?: QueryOptions<Faq & { q: string }>): Promise<{ list: Faq[], count: number }> {
		const result = await rpc_invoke(`search_${this.plural}`, { ...filter });
		return result.data;
	}
}