

export function getDisplayName(user?: { username: string, name?: string, user_name?: string } | null) {
  if (!user) {
    return "";
  }
  let displayName: string = user.user_name!;
  if (!displayName) {
    displayName = user.name!;
  }

  if (!displayName) {
    displayName = user.username;
  }

  return displayName;
}