import { QuestionCircleOutlined } from "@ant-design/icons";
import { Breadcrumb, Descriptions, DescriptionsProps, Image, Space, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { asNum } from 'utils-min';
import { SourceOrder } from '../../bindings/SourceOrder';
import { sourceOrderDco } from '../../dco/dcos';
import { APP_DATETIME_FORMAT } from '../../ts/conf';
import { EnumConf } from '../../ts/enum-config';
import { formatCurrency } from '../../ts/utils';
import { date } from '../../ts/utils-date';
import NoResult from '../NoResult';
import "./SourceOrderDetail.pcss";

function SourceOrderDetail() {
  const {orderId} = useParams();
  const [isLoading, setIsLoading] = useState(true); // 初始时设为加载中状态
  const [sourceOrder, setSourceOrder] = useState(null as SourceOrder | null);

  function refresh(){
    const id = asNum(orderId)!;
    Promise.all([
      sourceOrderDco.get(id), 
    ]).then(([c]) => {
      setSourceOrder(c);
      setIsLoading(false);
    })
  }

  useEffect(() => {
    refresh();
  }, []);
  
  return (
    <div className="SourceOrderDetail">
      <Breadcrumb className="breadcrumb" items={[{ title: <Link to="/src-orders">原始订单管理</Link> }, { title: "详情页" }, ]} />
      
      <div className='content'>
        {
          isLoading 
            ? ""
            : (
              sourceOrder
                ? (
                  detailPage(sourceOrder)
                )
                : (<NoResult />)
          )
        }
        
      </div>
    </div>
  );
}

function detailPage(sourceOrder: SourceOrder){

  const borderedItems: DescriptionsProps['items'] = [
    {
      key: 'main_pic',
      label: '图片',
      span: 1,
      children: (<Image height={80} width={80} src={sourceOrder.main_pic}></Image>),
    },
    {
      key: 'u_sku_name',
      label: '名称',
      span: 3,
      children: sourceOrder.u_sku_name,
    },
    {
      key: 'u_sku_id',
      label: 'skuID',
      children: sourceOrder.u_sku_id,
    },
    {
      key: 'order_id',
      label: '订单ID',
      children: sourceOrder.u_sku_id,
    },
    {
      key: 'order_no',
      label: '订单号',
      children: sourceOrder.order_no,
    },
    {
      key: 'sub_union_id',
      label: '子联盟ID',
      children: <Space direction='vertical'>
        <span>{sourceOrder.sub_union_id}</span>
      </Space>,
    },
    {
      key: 'u_order_time',
      label: '下单时间',
      children: <Space direction='vertical' align='center'>{date(sourceOrder.u_order_time).format(APP_DATETIME_FORMAT).split(" ").map(item => <div key={item}>{item}</div>)}</Space>,
    },
    {
      key: 'u_modify_time',
      label: '更新时间',
      children: <Space direction='vertical' align='center'>{date(sourceOrder.u_modify_time).format(APP_DATETIME_FORMAT).split(" ").map(item => <div key={item}>{item}</div>)}</Space>,
    },
    {
      key: 'u_finish_time',
      label: '完成时间',
      children: <Space direction='vertical' align='center'>{sourceOrder.u_finish_time ? date(sourceOrder.u_finish_time).format(APP_DATETIME_FORMAT).split(" ").map(item => <div key={item}>{item}</div>) : ""}</Space>,
    },
    {
      key: 'u_price',
      label: '价格',
      children: `￥${formatCurrency(sourceOrder.u_price)}`,
    },
    {
      key: 'u_estimate_cos_price',
      label: <>
              预估佣金额
              &nbsp;
              <Tooltip title="由订单的实付金额拆分至每个商品的预估计佣金额，不包括运费，以及京券、东券、E卡、余额等虚拟资产支付的金额。仅为预估值，实际佣金以actualCosPrice为准进行计算" ><QuestionCircleOutlined /></Tooltip>
            </>,
      children: `￥${formatCurrency(sourceOrder.u_estimate_cos_price)}`,
    },
    {
      key: 'u_estimate_fee',
      label: <>
              推客预估佣金
              &nbsp;
              <Tooltip title="（预估计佣金额*佣金比例*最终比例），如订单完成前发生退款，此金额也会更新" ><QuestionCircleOutlined /></Tooltip>
            </>,
      children: `￥${formatCurrency(sourceOrder.u_estimate_fee)}`,
    },
    {
      key: 'u_actual_cos_price',
      label: <>
              实际佣金
              &nbsp;
              <Tooltip title="订单完成后，会将误扣除的运费券金额更正。如订单完成后发生退款，此金额会更新" ><QuestionCircleOutlined /></Tooltip>
            </>,
      children: `￥${formatCurrency(sourceOrder.u_actual_cos_price)}`,
    },
    {
      key: 'u_actual_fee',
      label: <>
              推客实际佣金
              &nbsp;
              <Tooltip title="（实际计佣金额*佣金比例*最终比例）。如订单完成后发生退款，此金额会更新" ><QuestionCircleOutlined /></Tooltip>
            </>,
      children: `￥${formatCurrency(sourceOrder.u_actual_fee)}`,
    },
    {
      key: 'u_commission_rate',
      label: '计划佣金比例',
      children: `${sourceOrder.u_commission_rate}%`,
    },
    {
      key: 'u_sub_side_rate',
      label: '分成比例',
      children: `${sourceOrder.u_sub_side_rate}%`,
    },
    {
      key: 'u_sub_sidy_rate',
      label: '补贴比例',
      children: `${sourceOrder.u_sub_sidy_rate}%`,
    },
    {
      key: 'u_final_rate',
      label: '实际比例',
      children: `${sourceOrder.u_final_rate}%`,
    },
    {
      key: 'source',
      label: '来源',
      children: EnumConf.CommoditySource[sourceOrder.source],
    },
    {
      key: 'u_ext1',
      label: '扩展字段',
      children: sourceOrder.u_ext1,
    },
    {
      key: 'u_pay_month',
      label: '预估结算时间',
      children: sourceOrder.u_pay_month,
    },
  ];

  return (
    <Space direction="vertical" className='detail-main'>
      <a href={sourceOrder.main_url} target='_blank'>原商品链接</a>
      
      <Descriptions
        bordered
        column={4}
        title="订单详情信息"
        items={borderedItems}
      />

    </Space>
  );
}

export default SourceOrderDetail;