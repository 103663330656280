import { commodityCouponDco } from '../../dco/dcos';
import { CouponInfo } from '../../ts/utils-commodity';
import { date, formatDateTime } from '../../ts/utils-date';
import './CouponGetTag.pcss';


function CouponGetTag (props: {val:CouponInfo}) {
  async function goToCoupon(){
    let url = await commodityCouponDco.getShortUrl(props.val?.coupon.id!);
    if(url){
      window.open(url, "_blank");
    }
  }
  return (
    <div
      className='CouponGetTag'
      onClick={goToCoupon}
    >
      <span className='val-sec'><span className='val'>{props.val?.coupon.coupon_style == 'Discount' ? props.val?.coupon.discount * 10 : props.val?.coupon.discount}</span>{props.val?.style}</span>
      <span className='time'>有效期: {formatDateTime(date(props.val?.coupon.use_end_time), "YYYY-MM-DD HH:mm:ss")}</span>
      <span className='action-get'>马上领取</span>
    </div>
  )
}
export default CouponGetTag;