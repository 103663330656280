import { SourceOrder } from '../bindings/SourceOrder';
import { QueryOptions } from '../ts/query_options';
import { rpc_invoke } from '../ts/rpc';
import { BaseDco } from './dco-base';



export class SourceOrderDco extends BaseDco<SourceOrder, QueryOptions<SourceOrder>> {
	constructor() { super('source_order') }

	async searchOrders(filter?: QueryOptions<SourceOrder>): Promise<{ list: SourceOrder[], count: number }> {
		const result = await rpc_invoke(`search_${this.plural}`, { ...filter });
		return result.data;
	}
}