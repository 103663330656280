import { CommodityCoupon } from '../bindings/CommodityCoupon';
import { rpcCommonPrx } from '../ts/conf';
import { QueryOptions } from '../ts/query_options';
import { rpc_invoke } from '../ts/rpc';
import { BaseDco } from './dco-base';



export class CommodityCouponDco extends BaseDco<CommodityCoupon, QueryOptions<CommodityCoupon>> {
	constructor() { super('commodity_coupon') }

	async getShortUrl(id: number): Promise<string> {
		const result = await rpc_invoke(`get_coupon_short_url`, { id }, undefined, rpcCommonPrx);
		return result.data;
	}
}