import { Breadcrumb, Descriptions, DescriptionsProps, Space } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { asNum } from 'utils-min';
import { Feedback } from '../../bindings/Feedback';
import { User } from '../../bindings/User';
import { feedbackDco, userDco } from '../../dco/dcos';
import { getDisplayName } from '../../ts/utils-user';
import NoResult from '../NoResult';
import "./FeedbackDetail.pcss";

function FeedbackDetail() {
  const {feedbackId} = useParams();
  const [isLoading, setIsLoading] = useState(true); // 初始时设为加载中状态
  const [feedback, setFeedback] = useState(null as Feedback | null);
  const [user, setUser] = useState(null as User | null);

  function refresh(){
    const id = asNum(feedbackId)!;
    Promise.all([
      feedbackDco.get(id), 
    ]).then(([c]) => {
      setFeedback(c);
      setIsLoading(false);
      if(c.user_id){
        userDco.get(c.user_id).then(user=>{
          setUser(user);
        })
      }
    })
  }

  useEffect(() => {
    refresh();
  }, []);

  return (
    <div className="FeedbackDetail">
      <Breadcrumb className="breadcrumb" items={[{ title: <Link to="/feedbacks">用户反馈</Link> }, { title: "详情页" }, ]} />
      
      <div className='content'>
        {
          isLoading 
            ? ""
            : (
              feedback
                ? (
                  detailPage(feedback, user)
                )
                : (<NoResult />)
          )
        }
        
      </div>
    </div>
  );
}

function detailPage(feedback: Feedback, user:User | null){

  const borderedItems: DescriptionsProps['items'] = [
    {
      key: 'user_id',
      label: '用户',
      children: getDisplayName(user),
    },
    {
      key: 'title',
      label: '标题',
      children: feedback.title,
    },
    {
      key: 'content',
      label: '内容',
      children: <><div className='feedback-content'>{feedback.content}</div></>,
    },
  ];

  return (
    <Space direction="vertical" className='detail-main'>      
      <Descriptions
        bordered
        column={1}
        title="详情信息"
        items={borderedItems}
      />
    </Space>
  );
}

export default FeedbackDetail;