import { hub } from './dom-native/hub';
import { date, now } from './utils-date';

let expiredStore: string | undefined;
let timeoutId: any;
export function refreshCookieTimeout(expiredStr?: string) {
	if (expiredStr) {
		// set expire time
		const mon = date(expiredStr);
		const mills = mon.diff(now(), "milliseconds");
		expiredStore = expiredStr;

		// set a timeout to check if need token expired, if not reset timeout to check
		clearCookieTimeout();
		timeoutId = setTimeout(() => {
			expiredStr = expiredStore;
			if (expiredStr) {
				const mon = date(expiredStr);
				if (mon.diff(now(), "milliseconds") < 0) {
					expiredStore = undefined;
					hub("ajaxHub").pub("ERROR", { success: false, code: "NO_AUTH", message: "TIMEOUT" });
				} else {
					refreshCookieTimeout(expiredStr);
				}
			} else {
				expiredStore = undefined;
				hub("ajaxHub").pub("ERROR", { success: false, code: "NO_AUTH", message: "TIMEOUT" });
			}
		}, mills);
	}
}

export function clearCookieTimeout() {
	if (timeoutId) {
		clearTimeout(timeoutId);
	}
}
