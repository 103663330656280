import { CommoditySource } from '../bindings/CommoditySource';
import { SelLib } from '../bindings/SelLib';
import { QueryOptions } from '../ts/query_options';
import { rpc_invoke } from '../ts/rpc';
import { BaseDco } from './dco-base';



export class SelLibDco extends BaseDco<SelLib, QueryOptions<SelLib>>{
	constructor() { super('sel_lib') }

	async searchSelLibs(filter: QueryOptions<SelLib>): Promise<{ list: SelLib[], count: number }> {
		const result = await rpc_invoke(`search_${this.plural}`, { ...filter });
		return result.data;
	}

	async sync(items: { sec_lib_id: string, name: string, source: CommoditySource }[], source: CommoditySource) {
		await rpc_invoke(`sync_${this.cmd_suffix}`, { items, source });
	}
}