import { Cashout } from '../bindings/Cashout';
import { CashoutForSearch } from '../bindings/CashoutForSearch';
import { PayWay } from '../bindings/PayWay';
import { QueryOptions } from '../ts/query_options';
import { rpc_invoke } from '../ts/rpc';
import { BaseDco } from './dco-base';



export class CashoutDco extends BaseDco<Cashout, QueryOptions<Cashout>> {
	constructor() { super('cashout') }

	async searchCashouts(filter?: QueryOptions<Cashout>): Promise<{ list: CashoutForSearch[], count: number }> {
		const result = await rpc_invoke(`search_${this.plural}`, { ...filter });
		return result.data;
	}

	async markPay(id: number, success: boolean, note?: string): Promise<number> {
		const result = await rpc_invoke(`mark_pay`, { id, success, note });
		return result.data;
	}

	async getCashoutPayWay(): Promise<PayWay> {
		const result = await rpc_invoke(`get_cashout_payway`, {});
		return result.data;
	}
}