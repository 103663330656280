import { MouseEventHandler } from 'react';
import './AppIcon.pcss';


interface IconProps{
  name?: string;
  className?: string;
  onClick?: MouseEventHandler<HTMLElement> | undefined;
}

function AppIcon (props: IconProps) {
  return (
    <span className={['AppIcon', 'iconfont', `icon-${props.name!}`, props.className ?? ""].join(" ")} onClick={props.onClick}  />
  )
}
export default AppIcon;