import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Form, Input, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useCallback, useState } from 'react';
import { Feedback } from '../../bindings/Feedback';
import { feedbackDco } from '../../dco/dcos';
import { getCurrentUser } from '../../ts/auth-user';
import { antdModal } from '../../ts/nice-modal-fix';
import "./FeedbackDg.pcss";

function FeedbackDg({ feedback }: { feedback?: Feedback }) {
  const modal = useModal();
  const [form] = Form.useForm();
  const [data] = useState(feedback);

  const handleSubmit = useCallback(() => {
    form.validateFields().then(async () => {
      const newFeedback = { ...form.getFieldsValue() };

      const curUser = await getCurrentUser();
      if(!data?.id){
        newFeedback.user_id = curUser?.id;
        await feedbackDco.create(newFeedback);
      }else{
        await feedbackDco.update(data?.id!, newFeedback);
      }
      modal.resolve(newFeedback);
      modal.hide();
    }).catch((e) => {console.log(e)});
  }, [modal, feedback, form]);


  const validateMessages = {
    required: '${label}是必填项!',
  }

  const action = feedback ? "修改" : "添加";
  return (
    <Modal
      {...antdModal(modal)}
      title={`${action}用户反馈`}
      okText={action}
      onOk={handleSubmit}
      className='FeedbackDg'
    >
      <Form form={form}
        labelCol={{ span: 5 }}
        validateMessages={validateMessages}
        initialValues={{
          title:data?.title ?? "",
          content:data?.content ?? "",
        }}>
        <Form.Item name="title" label="标题" rules={[{ required: true }]} >
          <Input />
        </Form.Item>
        <Form.Item name="content" label="内容" rules={[{ required: true }]} >
          <TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default NiceModal.create(FeedbackDg);