import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Form, Modal, Select, Space } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useCallback, useState } from 'react';
import { Cashout } from '../../bindings/Cashout';
import { cashoutDco } from '../../dco/dcos';
import { antdModal } from '../../ts/nice-modal-fix';
import { getDisplayName } from '../../ts/utils-user';
import "./PayDg.pcss";

function PayDg({ cashout }: { cashout: Cashout }) {
  const modal = useModal();
  const [form] = Form.useForm();
  const [data] = useState(cashout);

  const handleSubmit = useCallback(() => {
    form.validateFields().then(async () => {
      const formData = { ...form.getFieldsValue() };
      await cashoutDco.markPay(data.id, formData.success == "true", formData.note);
      modal.resolve();
      modal.hide();
    }).catch((e) => {console.log(e)});
  }, [modal, cashout, form]);


  const validateMessages = {
    required: '${label}是必填项!',
  }

  return (
    <Modal
      {...antdModal(modal)}
      title={`付款处理`}
      okText={"处理"}
      onOk={handleSubmit}
      className='PayDg'
      width={600}
    >
      <Form 
        form={form}
        labelCol={{ span: 4 }}
        validateMessages={validateMessages}
        initialValues={{
        }}>
        <Form.Item label="订单号:">
          <span>{cashout.trans_id}</span>
        </Form.Item>
        <Form.Item label="用户:">
          <Space><span>{getDisplayName(cashout as any)}</span><span>({cashout.user_id})</span></Space>
        </Form.Item>
        <Form.Item name="success" label="标记付款为:" rules={[{ required: true, message: '付款状态不能为空'}]} >
          <Select>
            <Select.Option value="true">成功</Select.Option>
            <Select.Option value="false">失败</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="note" label="备注:" rules={[{ required: true }]} >
          <TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default NiceModal.create(PayDg);