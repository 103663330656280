import { DeleteOutlined, EditOutlined, PlusOutlined, SyncOutlined } from "@ant-design/icons";
import { useModal } from '@ebay/nice-modal-react';
import { Breadcrumb, Button, Pagination, Select, SelectProps, Space } from 'antd';
import Search from 'antd/es/input/Search';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isNotEmpty, pruneEmpty } from 'utils-min';
import { CommoditySource } from '../../bindings/CommoditySource';
import { SelLib } from '../../bindings/SelLib';
import ConfirmDg from '../../component/ConfirmDg';
import ScrollTable from '../../component/ScrollTable';
import { selLibDco } from '../../dco/dcos';
import { EnumArray, EnumConf } from '../../ts/enum-config';
import { QueryFilter, QueryOptions } from '../../ts/query_options';
import SelLibDg from './SelLibDg';
import "./SelLibsView.pcss";
import SyncDg from './SyncDg';

function SelLibsView() {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([] as SelLib[]);
  const [count, setCount] = useState(0);
  let [searchParams, setSearchParams] = useSearchParams();

  const [qVal, setQVal] = useState(searchParams.get("q"));
  const [sourceVal, setSourceVal] = useState(searchParams.get("source") as CommoditySource);
  const [pageIndex, setPageIndex] = useState(searchParams.get("pageIndex") ? parseInt(searchParams.get("pageIndex")!) : 1);
  const [pageSize, setPageSize] = useState(searchParams.get("pageSize") ? parseInt(searchParams.get("pageSize")!) : 50);

  const selLibModal = useModal(SelLibDg);
  const syncModal = useModal(SyncDg);
  const confirmModal = useModal(ConfirmDg);
  function refresh() {
    setLoading(true);
    let filter:QueryOptions<SelLib> = {};
    if(isNotEmpty(qVal) || isNotEmpty(sourceVal)){
      const filters:QueryFilter<SelLib & {q:string}> = {};
      if(qVal){
        filters.q = qVal;
      }
      if(sourceVal){
        filters.source = {'$eq': sourceVal};
      }
      filter.filters = filters;
    }

    filter.list_options = {offset: (pageIndex - 1) * pageSize, limit: pageSize}
    selLibDco.searchSelLibs(filter).then((result) => {
      setItems(result.list);
      setCount(result.count);
      setLoading(false);
    });
  }

  function triggerChange(){
    setSearchParams(pruneEmpty({
      q: qVal!,
      source: sourceVal,
      pageIndex: pageIndex?.toString(),
      pageSize: pageSize?.toString()
    }))
  }

  useEffect(() => {
    refresh();
    setSourceVal(searchParams.get("source") as CommoditySource);
    setQVal(searchParams.get("q"));
    setPageIndex(searchParams.get("pageIndex") ? parseInt(searchParams.get("pageIndex")!) : 1);
    setPageSize(searchParams.get("pageSize") ? parseInt(searchParams.get("pageSize")!) : 50);
  }, [searchParams]);

  useEffect(() => {
    triggerChange();
  }, [sourceVal, pageIndex, pageSize])
  
  const columns = [
    {
      title: '名称',
      dataIndex: 'name',
    },
    {
      title: '联盟ID',
      dataIndex: 'sel_lib_union_id',
    },
    {
      title: '来源',
      key: 'source',
      render: (_:any, rec:SelLib) => {
        return EnumConf.CommoditySource[rec.source];
      },
    },
    {
      title: '操作',
      key: 'action',
      render: (_:any, rec:SelLib) => (
        <Space size="middle">
          <Button size='small' type="primary" icon={<EditOutlined />}  onClick={() => onShowDg(rec)}>修改</Button>
          <Button size='small' danger icon={<DeleteOutlined />}  onClick={() => onDel(rec)}>删除</Button>
        </Space>
      ),
    },
  ];

  async function onShowDg(selLib?:SelLib){
    selLibModal.show({ selLib }).then(() => {
      refresh();
    });
  }

  async function onPreSync(){
    syncModal.show().then(() => {
      refresh();
    });
  }

  async function onDel(selLib:SelLib){
    confirmModal.show({
      content: '确定删除该选品库吗？',
      onDone: async () => {
        await selLibDco.delete(selLib.id);
        refresh();
      }
    });
  }

  
  const data = (items ?? []).map((item:any) => {
    return {...item, key: item.id};
  });
  return (
    <div className="SelLib screen">
      <Breadcrumb className="breadcrumb" items={[{ title: "选品库管理" }]} />
      <div className="screen-table-actions">
        <div className="filter-con">
          <div className='filter-item'>
            <Search placeholder="关键字搜索..." value={qVal!} onKeyUp={(e) => {
              if(e.key == 'Enter'){
                triggerChange();
              }
            }} onChange={(e) => {
              setQVal(e.target.value!);
            }}/>
          </div>
          <div className='filter-item'>
            <span>来源：</span>
            <Select
              value={sourceVal}
              onChange={(val) => {
                setSourceVal(val);
              }}
              allowClear
              placeholder="选择来源"
              options={EnumArray.CommoditySource as SelectProps["options"]}
              maxTagCount='responsive'
            />
          </div>
        </div>
        <div className="action-items">
          <Button className="action-item" type='primary' icon={<PlusOutlined />} onClick={() => onShowDg()}>添加</Button>
          <Button className="action-item" type='primary' icon={<SyncOutlined />} onClick={onPreSync}>同步</Button>
        </div>
      </div>
      <ScrollTable loading={loading} className="screen-table" columns={columns} dataSource={data} pagination={false} />
      <Pagination showQuickJumper showSizeChanger showTotal={(total) => `总共 ${total} 条`} defaultCurrent={1} total={count} pageSize={pageSize} current={pageIndex} onChange={(pageIndex, pageSize) => {
        setPageIndex(pageIndex);
        setPageSize(pageSize);
      }} />
    </div>
  );
}

export default SelLibsView;





