import { DeleteOutlined, EditOutlined, PlusOutlined, SyncOutlined } from "@ant-design/icons";
import { useModal } from '@ebay/nice-modal-react';
import { Breadcrumb, Button, Image, Input, Pagination, Select, SelectProps, Space, Typography, message } from 'antd';
import Search from 'antd/es/input/Search';
import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { asNum, isNotEmpty, pruneEmpty } from 'utils-min';
import { Commodity } from '../../bindings/Commodity';
import { CommodityElite } from '../../bindings/CommodityElite';
import { CommoditySource } from '../../bindings/CommoditySource';
import { SelLib } from '../../bindings/SelLib';
import AppIcon from '../../component/AppIcon';
import ConfirmDg from '../../component/ConfirmDg';
import ScrollTable from '../../component/ScrollTable';
import { commodityDco, selLibDco } from '../../dco/dcos';
import { EnumArray, EnumConf } from '../../ts/enum-config';
import { QueryFilter, QueryOptions } from '../../ts/query_options';
import { formatCurrency } from '../../ts/utils';
import "./CommoditiesView.pcss";
import CommodityContentDg from './CommodityContentDg';
import CommodityDg from './CommodityDg';
import CommoditySyncDg from './CommoditySyncDg';
import CommoditySyncEliteDg from './CommoditySyncEliteDg';

function CommoditiesView() {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([] as Commodity[]);
  const [count, setCount] = useState(0);
  const [selLibOptions, setSelLibOptions] = useState([] as SelectProps['options']);

  let [searchParams, setSearchParams] = useSearchParams();

  const [qVal, setQVal] = useState(searchParams.get("q"));
  const [selLibVal, setSelLibVal] = useState(searchParams.get("lib_id")?.split(","));
  const [sourceVal, setSourceVal] = useState(searchParams.get("source") as CommoditySource);
  const [eliteVal, setEliteVal] = useState(searchParams.get("elite") as CommodityElite);
  const [maxCommissionVal, setMaxCommissionVal] = useState(asNum(searchParams.get("max_commision")));
  const [minCommissionVal, setMinCommissionVal] = useState(asNum(searchParams.get("min_commision")));
  const [pageIndex, setPageIndex] = useState(searchParams.get("pageIndex") ? parseInt(searchParams.get("pageIndex")!) : 1);
  const [pageSize, setPageSize] = useState(searchParams.get("pageSize") ? parseInt(searchParams.get("pageSize")!) : 20);

  const commodityModal = useModal(CommodityDg);
  const syncModal = useModal(CommoditySyncDg);
  const syncEliteModal = useModal(CommoditySyncEliteDg);
  const contentModal = useModal(CommodityContentDg);
  const confirmModal = useModal(ConfirmDg);

  function refresh() {
    setLoading(true);
    let filter:QueryOptions<Commodity> = {};
    if(isNotEmpty(qVal) || isNotEmpty(selLibVal) || isNotEmpty(sourceVal)|| isNotEmpty(eliteVal) || isNotEmpty(maxCommissionVal) || isNotEmpty(minCommissionVal)){
      const filters:QueryFilter<Commodity & {sel_lib_union_id:string}> = {};
      if(qVal){
        filters.title = {'$contains': qVal};
      }

      if(isNotEmpty(selLibVal)){
        filters.sel_lib_union_id = {'$in': selLibVal};
      }

      if(isNotEmpty(sourceVal)){
        filters.source = {'$eq': sourceVal};
      }

      if(isNotEmpty(eliteVal)){
        filters.elite = {'$eq': eliteVal};
      }
      
      let commission:any = {};
      if(isNotEmpty(minCommissionVal)){
        commission['$gte'] = minCommissionVal;
      }

      if(isNotEmpty(maxCommissionVal)){
        commission['$lte'] = maxCommissionVal;
      }

      filters.commission = commission;
      filter.filters = filters;
    }

    filter.list_options = {offset: (pageIndex - 1) * pageSize, limit: pageSize}
    commodityDco.searchCommodities(filter).then(({list, count}) => {
      setItems(list);
      setCount(count);
      setLoading(false);
    });
  }

  function triggerChange(){
    setSearchParams(pruneEmpty({
      q: qVal!,
      lib_id: selLibVal?.join(","),
      source: sourceVal,
      elite: eliteVal,
      max_commision: maxCommissionVal? maxCommissionVal.toString() : "",
      min_commision: minCommissionVal ? minCommissionVal.toString() : "",
      pageIndex: pageIndex?.toString(),
      pageSize: pageSize?.toString()
    }))
  }

  useEffect(() => {
    refresh();
    setQVal(searchParams.get("q"));
    setPageIndex(searchParams.get("pageIndex") ? parseInt(searchParams.get("pageIndex")!) : 1);
    setPageSize(searchParams.get("pageSize") ? parseInt(searchParams.get("pageSize")!) : 20);
    setMaxCommissionVal(asNum(searchParams.get("max_commision")));
    setMinCommissionVal(asNum(searchParams.get("min_commision")));
    setSelLibVal(searchParams.get("lib_id")?.split(","));
    setSourceVal(searchParams.get("source") as CommoditySource);
    setEliteVal(searchParams.get("elite") as CommodityElite);
  }, [searchParams]);

  useEffect(() => {
    triggerChange();
  }, [selLibVal, sourceVal, eliteVal, pageIndex, pageSize])

  useEffect(() => {
    Promise.all([selLibDco.list()]).then(([options]) => {
      setSelLibOptions(options.map((item:SelLib) => {
        return {label: item.name, value: item.sel_lib_union_id};
      }))
    })
  }, []);

  const columns = [
    {
      title: '商品信息',
      dataIndex: 'title',
      render: (_:any, rec: Commodity) => (
        <Space>
          <Image height={80} width={80} src={rec.main_pic}></Image>
          <Space direction='vertical'>
            <Link to={`/commodities/${rec.id}`}>{rec.title}</Link>
            <Space size={2}>
              <AppIcon name='shop' className="secondary"></AppIcon>
              <Typography.Text type='secondary'>{rec.shop_name}</Typography.Text>
            </Space>
          </Space>
        </Space>
      )
    },
    {
      title: '产品ID',
      dataIndex: 'product_id',
      width: 160,
    },
    {
      title: '最终价格',
      key: 'price',
      width: 100,
      render: (_:any, rec:Commodity) => {
        return `￥${formatCurrency(rec.price)}`;
      },
    },
    {
      title: '佣金',
      key: 'commission',
      width: 90,
      render: (_:any, rec:Commodity) => {
        return `￥${formatCurrency(rec.commission)}`;
      },
    },
    {
      title: '佣金比例',
      key: 'commission_share',
      width: 90,
      render: (_:any, rec:Commodity) => {
        return `${rec.commission_share}%`;
      },
    },
    {
      title: '月销量',
      dataIndex: 'sell_count',
      width: 80,
    },
    {
      title: '来源',
      key: 'source',
      width: 80,
      render: (_:any, rec:Commodity) => {
        return EnumConf.CommoditySource[rec.source];
      },
    },
    {
      title: '状态',
      key: 'status',
      width: 80,
      render: (_:any, rec:Commodity) => {
        return EnumConf.CommodityStatus[rec.status];
      },
    },
    {
      title: '操作',
      key: 'action',
      width: 200,
      render: (_:any, rec:Commodity) => (
        <Space size="middle">
          <Space direction='vertical'>
            <a href={rec.main_url} target='_blank'>商品原链接</a>
            <Button size='small' type="primary" icon={<EditOutlined />}  onClick={() => onShowContentDg(rec)}>修改详情</Button>
          </Space>
          <Space direction='vertical'>
            <Button size='small' type="primary" icon={<EditOutlined />}  onClick={() => onShowDg(rec)}>修改</Button>
            <Button size='small' danger icon={<DeleteOutlined />}  onClick={() => onDel(rec)}>删除</Button>
          </Space>
        </Space>
      ),
    },
  ];

  async function onShowDg(commodity?:Commodity){
    commodityModal.show({ commodity }).then(() => {
      refresh();
    });
  }

  async function onShowContentDg(commodity?:Commodity){
    contentModal.show({ commodity }).then();
  }

  async function onPreSync(){
    syncModal.show({onSync: async (sel_lib_union_id) => {
      if(sel_lib_union_id){
        let {count} = await commodityDco.syncCommodities(sel_lib_union_id);
        message.success(`${count}个同步成功！`);
        refresh();
      }
    }});
  }

  async function onPreSyncElite(){
    syncEliteModal.show({onSync: async (elite, maxTotal) => {
      if(elite){
        let {count} = await commodityDco.syncEliteCommodities(elite, maxTotal);
        message.success(`${count}个同步成功！`);
        refresh();
      }
    }});
  }

  async function onDel(commodity:Commodity){
    confirmModal.show({
      content: '确定删除该商品吗？',
      onDone: async () => {
        await commodityDco.delete(commodity.id);
        refresh();
      }
    });
  }
  
  const data = (items ?? []).map((item:any) => {
    return {...item, key: item.id};
  });

  return (
    <div className="CommoditiesView screen">
      <Breadcrumb className="breadcrumb" items={[{ title: "商品管理" }]} />
      <div className="screen-table-actions">
        <div className="filter-con">
          <div className='filter-item'>
            <Search placeholder="关键字搜索..." value={qVal!} onKeyUp={(e) => {
              if(e.key == 'Enter'){
                triggerChange();
              }
            }} onChange={(e) => {
              setQVal(e.target.value!);
            }}/>
          </div>
          <div className='filter-item'>
            <span>选品库：</span>
            <Select
              value={selLibVal}
              onChange={(val) => {
                setSelLibVal(val);
              }}
              mode="multiple"
              allowClear
              placeholder="选择商品库"
              options={selLibOptions}
              maxTagCount='responsive'
            />
          </div>
          <div className='filter-item'>
            <span>来源：</span>
            <Select
              value={sourceVal}
              onChange={(val) => {
                setSourceVal(val);
              }}
              allowClear
              placeholder="选择来源"
              options={EnumArray.CommoditySource as SelectProps["options"]}
              maxTagCount='responsive'
            />
          </div>
          <div className='filter-item'>
            <span>精选库：</span>
            <Select
              value={eliteVal}
              onChange={(val) => {
                setEliteVal(val);
              }}
              allowClear
              placeholder="选择精选库"
              options={EnumArray.CommodityElite as SelectProps["options"]}
              maxTagCount='responsive'
            />
          </div>
          <div className='filter-item'>
            <span>佣金：</span>
            <span className='filter-range-input'>
              <Input 
                value={minCommissionVal?.toString()}
                onChange={(e) => {
                  setMinCommissionVal(asNum(e.currentTarget.value));
                }}
                onKeyUp={(e) => {
                  if(e.key == 'Enter'){
                    triggerChange();
                  }
                }} 
              />
            </span>
            <span>&nbsp;到&nbsp;</span>
            <span className='filter-range-input'>
              <Input 
                value={maxCommissionVal?.toString()}
                onChange={(e) => {
                  setMaxCommissionVal(asNum(e.currentTarget.value));
                }}
                onKeyUp={(e) => {
                  if(e.key == 'Enter'){
                    triggerChange();
                  }
                }} 
              />
            </span>
          </div>
        </div>
        <div className="action-items">
          <Button className="action-item" type='primary' icon={<PlusOutlined />} onClick={() => onShowDg()}>添加</Button>
          <Button className="action-item" type='primary' icon={<SyncOutlined />} onClick={onPreSync}>同步</Button>
          <Button className="action-item" type='primary' icon={<SyncOutlined />} onClick={onPreSyncElite}>同步精选库</Button>
        </div>
      </div>
      <ScrollTable loading={loading} className="screen-table" columns={columns} dataSource={data} pagination={false} extraHeight={126} />
      <Pagination showQuickJumper showSizeChanger showTotal={(total) => `总共 ${total} 条`} defaultCurrent={1} total={count} pageSize={pageSize} current={pageIndex} onChange={(pageIndex, pageSize) => {
        setPageIndex(pageIndex);
        setPageSize(pageSize);
      }} />
    </div>
  );
}

export default CommoditiesView;





