import { Commodity } from '../bindings/Commodity';
import { CommodityContent } from '../bindings/CommodityContent';
import { CommodityElite } from '../bindings/CommodityElite';
import { rpcCommonPrx } from '../ts/conf';
import { QueryOptions } from '../ts/query_options';
import { rpc_invoke } from '../ts/rpc';
import { BaseDco } from './dco-base';



export class CommodityDco extends BaseDco<Commodity, QueryOptions<Commodity & { sel_lib_union_id: string }>> {
	constructor() { super('commodity', 'commodities') }


	async searchCommodities(filter: QueryOptions<Commodity & { sel_lib_union_id: string }>): Promise<{ list: Commodity[], count: number }> {
		const result = await rpc_invoke(`search_${this.plural}`, { ...filter });
		return result.data;
	}

	async getContent(commodity_id: number): Promise<CommodityContent | null> {
		const result = await rpc_invoke(`get_content`, { id: commodity_id });
		return result.data;
	}

	async saveContent(commodity_id: number, content: string): Promise<number> {
		const result = await rpc_invoke(`save_content`, { data: { commodity_id, content } });
		return result.data;
	}

	async syncCommodities(sel_lib_union_id: string): Promise<{ count: number }> {
		const result = await rpc_invoke(`sync_${this.plural}`, { sel_lib_union_id });
		return result.data;
	}

	async syncEliteCommodities(elite: CommodityElite, max_total?: number): Promise<{ count: number }> {
		const result = await rpc_invoke(`sync_elite_${this.plural}`, { elite, max_total });
		return result.data;
	}


	async getShortUrl(id: number): Promise<string> {
		const result = await rpc_invoke(`get_commodity_short_url`, { id }, undefined, rpcCommonPrx);
		return result.data;
	}
}