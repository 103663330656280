import { CountStats } from '../bindings/CountStats';
import { UserOrder } from '../bindings/UserOrder';
import { UserOrderForSearch } from '../bindings/UserOrderForSearch';
import { QueryOptions } from '../ts/query_options';
import { rpc_invoke } from '../ts/rpc';
import { BaseDco } from './dco-base';



export class UserOrderDco extends BaseDco<UserOrder, QueryOptions<UserOrderForSearch>> {
	constructor() { super('user_order') }

	async searchOrders(filter?: QueryOptions<UserOrderForSearch>): Promise<{ list: UserOrderForSearch[], count: number }> {
		const result = await rpc_invoke(`search_${this.plural}`, { ...filter });
		return result.data;
	}

	async recordedOrders(ids: number[]) {
		const result = await rpc_invoke(`recorded_${this.plural}`, { ids });
		return result.data;
	}

	async syncOrders(start_time: string, time_type: string): Promise<number> {
		const result = await rpc_invoke(`sync_${this.plural}`, { start_time, time_type });
		return result.data;
	}

	async getUserOrderStats(): Promise<CountStats> {
		const result = await rpc_invoke(`get_user_order_stats`, {});
		return result.data;
	}

	async getUserOrderEarningStats(): Promise<CountStats> {
		const result = await rpc_invoke(`get_user_order_earning_stats`, {});
		return result.data;
	}
}